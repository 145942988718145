import { ref, computed } from '@vue/composition-api'
import { defineContext } from '@/utils/composable/context'

/**
 * @typedef {object} Step
 * @property {string} key
 * @property {string | () => string} title
 * @property {ReturnType<typeof import('@vue/composition-api').defineComponent>} component
 */
/**
 * @typedef {object} BasicFlowOptions
 * @property {string} flowId
 * @property {Step[]} steps
 */

/** @param {BasicFlowOptions} */
export function createBasicFlowContext ({ flowId, steps: _steps }) {
  if (flowId.length === 0) throw new Error('A flow must have a flowId')
  if (_steps.length === 0) throw new Error('A flow must include at least a step')

  const steps = ref(_steps)
  const numOfSteps = computed(() => steps.value.length)
  const stepComponentList = computed(() => steps.value.map(({ component }) => component))
  const stepTitleList = computed(() => steps.value.map(({ title }) => {
    return String(typeof title === 'function' ? title() : title)
  }))
  const currentStepIndex = ref(0)
  const currentStep = computed(() => steps.value[currentStepIndex.value])
  const currentStepComponent = computed(() => stepComponentList.value[currentStepIndex.value])
  const currentStepTitle = computed(() => stepTitleList.value[currentStepIndex.value])
  const currentStepKey = computed(() => currentStep.value.key)
  const stepResultList = ref(Array.from(Array(numOfSteps.value), () => null))

  function handlePrevStep () {
    if (currentStepIndex.value === 0) return
    currentStepIndex.value -= 1
  }
  function handleNextStep () {
    if (currentStepIndex.value === numOfSteps - 1) return
    currentStepIndex.value += 1
  }
  /**
   * @param {number | string} step
   */
  function handleJumpStep (step) {
    if (typeof step === 'number') {
      if (step < 0 || step > numOfSteps - 1) return
      currentStepIndex.value = step
    } else if (typeof step === 'string') {
      const index = steps.value.findIndex(({ key }) => key === step)
      if (index === -1) return
      currentStepIndex.value = index
    }
  }
  function handleSaveResult (result = null) {
    stepResultList.value.splice(currentStepIndex.value, 1, result)
  }
  function resetFlowState () {
    currentStepIndex.value = 0
    stepResultList.value = Array.from(Array(numOfSteps.value), () => null)
  }

  return {
    flowId,
    steps,
    numOfSteps,
    currentStepIndex,
    currentStep,
    currentStepKey,
    currentStepComponent,
    currentStepTitle,
    stepComponentList,
    stepResultList,
    stepTitleList,
    handlePrevStep,
    handleNextStep,
    handleJumpStep,
    handleSaveResult,
    resetFlowState
  }
}

const {
  registerContext: _registerFlowContext,
  useContext: _useFlowContext
} = defineContext({
  namespace: 'context:flow',
  /**
   * @param {{ createFlowContext: () => any }}
   */
  createContext ({ createFlowContext }) {
    return createFlowContext()
  }
})

/**
 * @returns {ReturnType<typeof createBasicFlowContext>}
 */
export function useCurrentFlowContext () {
  return _useFlowContext()
}

/**
 * @template Opts, Ctx
 * @typedef {object} DefineOptions
 * @property {(options: Opts) => Ctx} createFlowContext
 */

/**
 * @template O, C
 * @param {DefineOptions<O, C>}
 */
export function defineFlowContext ({
  createFlowContext
}) {
  /**
   * @param {O} options
   * @returns {C}
   */
  function registerFlowContext (options) {
    const context = _registerFlowContext({
      createFlowContext: () => createFlowContext(options)
    })
    return context
  }

  /**
   * @returns {C}
   */
  function useFlowContext () {
    return _useFlowContext()
  }

  return {
    registerFlowContext,
    useFlowContext
  }
}
