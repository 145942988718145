import { ref, computed, onMounted } from '@vue/composition-api'
import { useCurrentFlowContext } from '@/modules/shared/flow/composable/flow'
import { isLocalFileFlowContext, isSftpFlowContext } from '@/modules/dataManagement/importFlow/composable'
import { isMiniAppImportFlowContext } from '@/modules/miniApp/importFlow/composable'
import { isModelUploadFilesContext } from '@/modules/modelManagement/importFlow/composable'
import { delay } from '@/utils/general'
import { UploadStatus } from '../constants'

const noop = () => {}

/**
 * @typedef {object} Dependencies
 * @property {() => UseUploadFileOperations} useUploadFileOperations
 * @property {() => UseHandleUpload} [useHandleUpload]
 */

/**
 * @typedef {object} UseUploadFileOperations
 * @property {(file: any) => Promise<any>} uploadFile
 */

/**
 * @typedef {object} UseHandleUpload
 * @property {() => void} handleUpload
 */

/**
 * @param {Dependencies}
 */
export function createBasicUploadFilesContext ({
  useUploadFileOperations,
  useHandleUpload
}) {
  const flowContext = useCurrentFlowContext()
  if (!isSftpFlowContext(flowContext) && !isLocalFileFlowContext(flowContext) && !isMiniAppImportFlowContext(flowContext) && !isModelUploadFilesContext(flowContext)) {
    throw new Error('Current flow is not a legal flow')
  }

  const {
    fileToUploadList,
    handleSaveResult,
    handleNextStep,
    handlePrevStep,
    handleExit
  } = flowContext
  const fileList = ref(fileToUploadList.value.map((file) => ({
    info: file,
    result: null,
    status: UploadStatus.UPLOADING,
    msg: '',
    cancelUpload: noop,
    progress: 'indeterminate'
  }))
  )
  const uploadingFileList = computed(() => {
    return fileList.value.map(({ info: { type, name, size }, status, progress }) => ({
      type,
      name,
      size,
      status,
      progress
    }))
  })
  const hasAtLeastOneSuccess = ref(false)

  const isUploading = ref(false)
  const { handleUpload = noop } = useHandleUpload?.() ?? {}
  const { uploadFile } = useUploadFileOperations()
  async function uploadFiles () {
    isUploading.value = true
    await Promise.all(fileList.value.map(async (file) => {
      try {
        file.result = await uploadFile(file)
        file.status = UploadStatus.SUCCESS
      } catch (e) {
        console.log(e)
        file.status = UploadStatus.FAIL
      }
    }))
    await delay(500)

    hasAtLeastOneSuccess.value = fileList.value.some(({ status }) => status === UploadStatus.SUCCESS)

    handleSaveResult({
      uploadedFileList: fileList.value
        .map(({ cancelUpload, ...uploadedFile }) => uploadedFile)
    })
    handleUpload()
    isUploading.value = false
  }
  function handleCancelUpload (index) {
    const file = fileList.value[index]
    file.cancelUpload()
  }

  onMounted(() => {
    uploadFiles()
  })

  return {
    isUploading,
    hasAtLeastOneSuccess,
    uploadingFileList,
    handleCancelUpload,
    handleNextStep,
    handlePrevStep,
    handleExit
  }
}
