<template>
  <div>
    <input
      style="display: none;"
      ref="fileUploadInput"
      :accept="acceptFileTypes"
      name="fileUploadInput"
      multiple
      type="file"
      @change="handleSelectFile($event.target.files)"
    >
    <template v-if="selectedFiles.length === 0">
      <step-card-block class="file-select">
        <upload-block
          :bottom-message="$t('editing.clickToSelectFiles')"
          :drag-enter="isDragEnter"
          class="empty-upload-block"
          @create="startSelectFile"
          @drop.native.prevent="dropFiles($event)"
          @dragover.native.prevent
          @dragenter.native="isDragEnter = true"
          @dragleave.native="isDragEnter = false"
        >
          <div
            slot="uploadLimit"
            class="empty-upload-block__remark"
          >
            <div class="title">
              【{{ $t('editing.uploadLimitTitle') }}】
            </div>
            <div
              v-for="(msg, index) in $t('model.upload.modelLimit')"
              :key="index"
            >
              {{ Number(index) + 1 }}. {{ $t(`model.upload.modelLimit[${index}]`, {mainScriptName}) }}
            </div>
          </div>
        </upload-block>
      </step-card-block>
    </template>
    <template v-else>
      <div
        :class="{ droppable: isDragEnter }"
        @dragover.prevent
        @dragenter="isDragEnter = true"
        @dragleave="isDragEnter = false"
        @drop.prevent="dropFiles($event)"
      >
        <div
          v-if="selectedFiles.length > 0"
          class="result-block"
        >
          <step-card-block class="list-container">
            <file-status-list
              :file-status-list="selectedFiles"
              @delete="handleDeleteSelectedFile(enableToUploadFiles[$event].index)"
            />
          </step-card-block>
        </div>
      </div>
      <div class="file-chosen-info">
        <span class="file-chosen-remark">
          {{ $t('editing.selectedTablesWaitingToUpload', {
            num: selectedFiles.length,
            size: byteToMB(totalTransmitDataBytes)
          }) }}
        </span>
        <button
          class="btn-m btn-secondary btn-has-icon"
          @click="startSelectFile"
        >
          <svg-icon
            icon-class="file-plus"
            class="icon"
          />
          {{ $t('editing.addFile') }}
        </button>
      </div>
    </template>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        v-if="selectedFiles.length > 0"
        class="btn btn-default"
        @click="handleClickNextStep"
      >
        {{ $t('button.confirmUpload') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { setupStepSelectFiles } from './composable'

import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import UploadBlock from '@/components/UploadBlock.vue'
import FileStatusList from '@/modules/dataManagement/importFlow/steps/stepLocalFileSelectFiles/components/FileStatusList.vue'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    UploadBlock,
    FileStatusList
  },
  props: {
    isReUpload: {
      type: Boolean,
      default: false
    }
  },
  setup (props, ctx) {
    return setupStepSelectFiles(props, ctx)
  }
})
</script>
<style lang="scss" scoped>
.file-select {
  overflow: hidden;
  padding: 0;
}

.empty-upload-block {
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;

  &__remark {
    color: $theme-color-warning;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;

    .title {
      margin-bottom: 4px;
    }
  }
}

.result-block {
  margin-bottom: 1rem;
}

.file-chosen-info {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin-bottom: 1rem;

  .file-chosen-remark {
    margin-right: 16px;
  }
}

.droppable .list-container {
  background-color: #485454;
}

</style>
