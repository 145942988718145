<template>
  <div class="single-file-row-container">
    <step-card-block
      type="bright"
      class="single-file-row"
    >
      <div class="single-file">
        <div class="single-file__info">
          <div class="file-info file-info--name">
            {{ name }}
          </div>
          <div class="file-info file-info--size">
            {{ byteToMB(size) }}
          </div>
        </div>
        <div class="single-file__status">
          <a
            class="link action-link"
            href="javascript:void(0)"
            @click="$emit('delete')"
          >
            {{ $t('button.delete') }}
          </a>
        </div>
      </div>
    </step-card-block>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'

export default defineComponent({
  name: 'SingleFileStatusRow',
  components: {
    StepCardBlock
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.single-file-row-container:not(:last-of-type) {
  margin-bottom: 1rem;
}

.single-file-row {
  background-color: rgba(67, 76, 76, 0.95);
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 20px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.single-file {
  display: flex;

  &__info {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    letter-spacing: 0.5px;
    line-height: 19px;
    margin-right: 15px;
  }

  &__status {
    padding: 0 1rem;

    .action-link {
      color: $theme-color-primary;
      letter-spacing: 0.5px;
      line-height: 19px;
    }
  }
}

</style>
