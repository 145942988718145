import { ref, computed } from '@vue/composition-api'
import { useMapState } from '@/utils/composable/vuex'
import { useI18n } from '@/utils/composable/i18n'
import { Message } from 'element-ui'
import { useLocalFileFlowContext } from '../../../composable'
import { FileTypeMap } from '../../../constants'

function useFileSelectInput (refs) {
  const { t } = useI18n()
  const {
    groupId
  } = useLocalFileFlowContext()
  const {
    fileCountLimit
  } = useMapState('dataManagement', ['fileCountLimit'])
  const {
    license
  } = useMapState('userManagement', ['license'])

  const acceptFileTypes = [
    '.csv',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
  ].join(',')

  /**
   * @typedef {
      {
        name: string;
        size: number;
        type: keyof typeof import('../../../constants').FileType;
        data: {
          formData: FormData
        };
      }
   * } File
   */
  /** @type {import('@vue/composition-api').Ref<File[]} */
  const selectedFiles = ref([])
  const isDragEnter = ref(false)

  const checkedSelectedFiles = computed(() =>
    selectedFiles.value
      .map((file, index) => ({
        index,
        ...file,
        ...checkFileSize(file)
      }))
  )
  const enableToUploadFiles = computed(() =>
    checkedSelectedFiles.value
      .filter((file) => file.enable)
  )
  const unableToUploadFiles = computed(() =>
    checkedSelectedFiles.value
      .filter((file) => !file.enable)
  )

  function checkFileSize (file) {
    const GB_TO_B = 1024 * 1024 * 1024
    const GB_TO_MB = 1024
    const isOverSizeLimit = license.value.maxUploadSize !== -1 &&
      file.size > license.value.maxUploadSize * GB_TO_B

    return {
      enable: !isOverSizeLimit,
      msg: isOverSizeLimit
        ? t('editing.reachUploadSizeLimit', {
          limitSize: license.value.maxUploadSize * GB_TO_MB
        })
        : ''
    }
  }

  function startSelectFile () {
    const uploadInput = refs.fileUploadInput
    uploadInput.value = ''
    uploadInput.click()
  }

  function handleSelectFile (files) {
    const inputFiles = Array.from(files ?? [])
    // 判斷數量是否超過限制
    if (inputFiles.length + selectedFiles.value.length > fileCountLimit.value) {
      Message({
        message: t('editing.reachUploadCountLimit', { countLimit: fileCountLimit.value }),
        type: 'warning',
        duration: 3 * 1000,
        showClose: true
      })
      return
    }

    const newSelectedFiles = inputFiles.map((inputFile) => {
      const formData = new FormData()
      formData.append('file', inputFile)
      formData.append('groupId', groupId.value)
      return {
        name: inputFile.name,
        size: inputFile.size,
        type: FileTypeMap[inputFile.type],
        data: {
          formData
        }
      }
    })

    selectedFiles.value = [...selectedFiles.value, ...newSelectedFiles]
  }

  function dropFiles (event) {
    if (!event.dataTransfer.files) return

    const entries = Array.from(event.dataTransfer.files)
      .map(file => {
        // 用副檔名判斷過濾
        return [
          /\.((csv)|(xlsx?))$/.test(file.name),
          file
        ]
      })

    const invalidFilenameStr = entries
      .filter(([result]) => !result)
      .map(([, file]) => `"${file.name}"`)
      .join(', ')

    invalidFilenameStr.length && Message({
      message: t('message.fileTypeNotSupported', { fileType: invalidFilenameStr }),
      type: 'warning',
      duration: 3 * 1000,
      showClose: true
    })

    const validFiles = entries
      .filter(([result]) => result)
      .map(([, file]) => file)

    handleSelectFile(validFiles)

    // 還原狀態
    isDragEnter.value = false
  }

  function handleDeleteSelectedFile (index) {
    selectedFiles.value.splice(index, 1)
  }

  return {
    fileCountLimit,
    license,
    acceptFileTypes,
    selectedFiles,
    enableToUploadFiles,
    unableToUploadFiles,
    startSelectFile,
    handleSelectFile,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile
  }
}

export function setupStepLocalFileSelectFiles ({
  refs
}) {
  const {
    handleSaveResult,
    handleNextStep,
    handlePrevStep,
    handleExit
  } = useLocalFileFlowContext()
  const {
    fileCountLimit,
    license,
    acceptFileTypes,
    selectedFiles,
    enableToUploadFiles,
    unableToUploadFiles,
    startSelectFile,
    handleSelectFile,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile
  } = useFileSelectInput(refs)

  // 是否無限制空間
  const isStorageSizeUnlimited = computed(() => {
    /** @type {boolean} */
    return license.value.maxDataStorageSize === -1
  })
  // 總資料傳輸量 Bytes
  const totalTransmitDataBytes = computed(() => {
    return enableToUploadFiles.value.reduce((acc, file) => {
      return acc + file.size
    }, 0)
  })
  // 剩餘空間 Bytes
  const remainingDataStorageSpaceBytes = computed(() => {
    if (isStorageSizeUnlimited.value) return -1
    const GB_TO_B = 1024 * 1024 * 1024
    const remaining = license.value.maxDataStorageSize - license.value.currentDataStorageSize
    return remaining > 0 ? remaining * GB_TO_B : 0
  })
  // 是否有足夠剩餘空間上傳
  const hasEnoughRemainingDataStorageSpace = computed(() => {
    if (isStorageSizeUnlimited.value) return true
    return remainingDataStorageSpaceBytes.value >= totalTransmitDataBytes.value
  })

  function handleClickNextStep () {
    /*
      type FileToUploadList = {
        name: string;
        size: number;
        type: FileType;
        data: {
          formData: FormData;
        };
      }[]
      */
    handleSaveResult({
      fileToUploadList: enableToUploadFiles.value.map(({ index, enable, msg, ...file }) => file)
    })
    handleNextStep()
  }

  return {
    fileCountLimit,
    license,
    acceptFileTypes,
    selectedFiles,
    enableToUploadFiles,
    unableToUploadFiles,
    startSelectFile,
    handleSelectFile,
    handleClickNextStep,
    handlePrevStep,
    handleExit,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile,
    totalTransmitDataBytes,
    remainingDataStorageSpaceBytes,
    hasEnoughRemainingDataStorageSpace
  }
}
