<template>
  <step-card-block
    type="bright"
    :class="{ 'single-file-result-row--fail': isFail }"
    class="single-file-result-row"
  >
    <div class="single-file">
      <div class="single-file__info">
        <label class="checkbox">
          <div
            :class="{
              indeterminate: isIndeterminate
            }"
            class="checkbox-label"
          >
            <input
              v-model="isSelectedFile"
              type="checkbox"
              :disabled="isFail"
            >
            <div class="checkbox-square" />
          </div>
          <svg-icon
            :icon-class="{
              [FileType.CSV]: 'file-csv',
              [FileType.EXCEL]: 'file-xlsx'
            }[type]"
          />
          <div>
            {{ name }}
          </div>
        </label>
        <div>
          {{ byteToMB(size) }}
        </div>
      </div>
      <div
        class="single-file__status"
        :class="{ 'single-file__status--fail': isFail }"
      >
        <svg-icon
          v-if="isFail"
          icon-class="alert"
        />
      </div>
    </div>
    <div
      v-if="type === FileType.EXCEL && tabDetails.length > 0"
      class="tabs"
    >
      <label
        v-for="(tab, index) in tabDetails"
        :key="`tab-${String(index)}`"
        class="checkbox"
      >
        <div class="checkbox-label">
          <input
            v-model="selectedTabs"
            :value="index"
            type="checkbox"
          >
          <div class="checkbox-square" />
        </div>
        <div>
          {{ tab.tabName }}
        </div>
      </label>
    </div>
  </step-card-block>
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'
import { FileType } from '../../../constants'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import { UploadStatus } from '@/modules/shared/flow/steps/stepUploadFiles/constants'

export default defineComponent({
  name: 'SingleFileResultRow',
  components: {
    StepCardBlock
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: true,
      validator: (value) => value !== UploadStatus.UPLOADING && value in UploadStatus
    },
    tabDetails: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    const isSelectedFile = ref(false)

    onMounted(() => {
      isSelectedFile.value = true
    })

    const allTabsIndex = ref(props.tabDetails.map((item, index) => index))
    const selectedTabs = ref([])

    const isFail = computed(() => {
      return props.status === UploadStatus.FAIL
    })
    const selectedList = computed(() => {
      if (isSelectedFile.value || selectedTabs.value.length > 0) {
        return [props.type, ...selectedTabs.value]
      } else {
        return []
      }
    })
    const isIndeterminate = computed(() => {
      return selectedTabs.value.length > 0 &&
        (allTabsIndex.value.length !== selectedTabs.value.length)
    })

    watch(selectedTabs, (value) => {
      if (props.tabDetails.length > 0) {
        if (value.length === allTabsIndex.value.length) {
          isSelectedFile.value = true
        } else {
          isSelectedFile.value = false
        }
      }
    })
    watch(isSelectedFile, (value) => {
      if (value) {
        selectedTabs.value = allTabsIndex.value
      } else if (selectedTabs.value.length === allTabsIndex.value.length) {
        selectedTabs.value = []
      }
    })
    watch(selectedList, (value) => {
      emit('input', value)
    })

    return {
      FileType,
      isSelectedFile,
      selectedTabs,
      isFail,
      isIndeterminate
    }
  }
})
</script>

<style lang="scss" scoped>
.single-file-result-row {
  font-size: 14px;
  padding: 10px 20px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &--fail {
    color: #bdbdbd;

    .checkbox {
      cursor: default;

      .checkbox-label {
        display: none;
      }
    }
  }

  .single-file {
    display: flex;

    &__info {
      align-items: center;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      letter-spacing: 0.5px;
      line-height: 19px;
      margin-right: 15px;
      position: relative;
    }

    &__status {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 52px;

      &--fail {
        color: #f1616d;
      }
    }
  }

  .tabs {
    padding-left: 3rem;
  }

  .checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 1.75rem;

    .checkbox-label {
      margin-right: 1rem;
    }

    .svg-icon {
      height: 20px;
      margin-right: 0.5rem;
      width: 20px;
    }
  }
}
</style>
