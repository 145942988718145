<template>
  <form>
    <div class="dialog__input-block">
      <label class="dialog__label">
        {{ $t('miniApp.miniAppName') }}
        <input-verify
          v-validate="`required|max:${max}`"
          v-model="formData.name"
          :placeholder="$t('miniApp.pleaseEnterName')"
          type="text"
          name="appSettingsName"
          :disabled="disabled"
        />
      </label>
    </div>
    <div class="dialog__input-block">
      <label class="dialog__label">
        {{ $t('miniApp.description') }}
        <input-verify
          v-validate="`max:${max}`"
          v-model="formData.description"
          :placeholder="$t('miniApp.pleaseEnterDescription')"
          type="text"
          name="appSettingsDescription"
          :disabled="disabled"
        />
      </label>
    </div>
    <div class="dialog__input-block">
      <label class="dialog__label">
        {{ $t('common.timezone') }}
        <time-zone-select
          v-validate="'required'"
          v-model="formData.timeZone"
          name="appSettingsTimeZone"
          :disabled="disabled"
        />
      </label>
    </div>
    <div class="dialog__icons-block">
      <label class="dialog__label">
        {{ $t('miniApp.chooseIcon') }}
      </label>
      <div class="dialog__icons-wrapper">
        <label
          v-for="(icon, index) in icons"
          :key="index"
          :class="{ 'active': formData.icon === icon }"
          class="dialog__icon-box"
        >
          <input
            v-validate="index === 0 ? `required|included:${icons.join()}` : null"
            :value="icon"
            v-model="formData.icon"
            name="appSettingsIcon"
            type="radio"
            class="dialog__icon-box-radio"
            :disabled="disabled"
          >
          <svg-icon
            :icon-class="icon"
            class="icon"
          />
        </label>
      </div>
      <div
        v-show="errors.has('appSettingsIcon')"
        class="input-error error-text"
      >
        {{ $t('miniApp.pleaseSelectAnIcon') }}
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment-timezone'
import { defineComponent, ref } from '@vue/composition-api'
import { useValidatorWithErrors } from '@/utils/composable/validator'
import { useMapGetters } from '@/utils/composable/vuex'
import InputVerify from '@/components/InputVerify'
import TimeZoneSelect from '@/components/select/TimeZoneSelect.vue'

const icons = [
  'jarvix-app',
  'global',
  'home',
  'anomaly-analysis',
  'bar-chart',
  'clock',
  'folder',
  'feature',
  'key',
  'len-with-line-chart'
]

export default defineComponent({
  name: 'AppSettingsForm',
  components: {
    InputVerify,
    TimeZoneSelect
  },
  props: {
    initData: {
      type: Object,
      default () {
        return {
          name: '',
          description: '',
          timeZone: moment.tz.guess(),
          icon: ''
        }
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    /**
     * @typedef {Record<'name'| 'description' | 'timeZone' | 'icon', string>} SettingsFormData
     */

    /** @type {SettingsFormData} */
    const clonedFormData = { ...props.initData }
    const formData = ref(clonedFormData)

    const { fieldCommonMaxLength: max } = useMapGetters('validation', ['fieldCommonMaxLength'])

    const { validator, errors } = useValidatorWithErrors()
    async function getFormData () {
      const valid = await validator.validateAll()
      if (!valid) return null
      return {
        ...formData.value
      }
    }

    return {
      errors,
      icons,
      max,
      formData,
      getFormData
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .dialog-inner-box {
  .dialog-select-text {
    margin-bottom: 24px;
  }

  .input-verify-text,
  .el-select {
    margin-bottom: 26px;
  }

  .input-error {
    bottom: 9px;
  }
}

.dialog {
  &__input-block,
  &__icons-block {
    text-align: left;
  }

  &__label {
    color: #ccc;
    font-size: 14px;
    font-weight: 600;
  }

  &__icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8px;
  }

  &__icons-block {
    margin-bottom: 15px;
  }

  &__icon-box {
    align-items: center;
    border: 2px solid #485454;
    border-radius: 12px;
    color: #ccc;
    cursor: pointer;
    display: flex;
    font-size: 32px;
    height: 75px;
    justify-content: center;
    margin-bottom: 12px;
    transition: all 0.1s linear;
    width: 84px;

    &.active,
    &:hover {
      background: #485454;
      border: 2px solid #2ad2e2;
      color: #fff;
    }
  }

  &__icon-box-radio {
    display: none;
  }
}
</style>
