import { computed } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import { createBasicImportFlowContext } from './basic'

import StepSetupConnection from '../steps/stepSetupConnection/StepSetupConnection.vue'
import StepDbSelectTable from '../steps/stepDbSelectTable/StepDbSelectTable.vue'
import StepProcessData from '../steps/stepProcessData/StepProcessData.vue'

export const FLOW_ID = 'db-flow'

/** @param {{ emit: import('@vue/composition-api').SetupContext['emit'] }} */
export function createDbFlowContext ({ emit }) {
  const { t } = useI18n()
  const basicImportFlowContext = createBasicImportFlowContext({
    emit,
    flowId: FLOW_ID,
    steps: [
      {
        key: 'SetupConnection',
        title: () => t('editing.connectDB'),
        component: StepSetupConnection
      },
      {
        key: 'SelectTable',
        title: () => t('editing.chooseDataSheet'),
        component: StepDbSelectTable
      },
      {
        key: 'ProcessData',
        title: () => t('editing.dataProcessing'),
        component: StepProcessData
      }
    ]
  })

  const { stepResultList } = basicImportFlowContext
  const dbConnectionId = computed(() => {
    return stepResultList.value[0]?.connectionId ?? null
  })
  const analyzedResultList = computed(() => {
    return stepResultList.value[1]?.analyzedResultList ?? []
  })

  return {
    ...basicImportFlowContext,
    dbConnectionId,
    analyzedResultList
  }
}
