import axios from 'axios'
import { uploadFile } from '@/API/RemoteSftpConnection'
import { useSftpFlowContext } from '../../../composable'
import { createBasicUploadFilesContext } from '@/modules/shared/flow/steps/stepUploadFiles/composable/basic'
import { useCurrentFlowContext } from '@/modules/shared/flow/composable/flow'

export function createSftpUploadFilesContext () {
  return createBasicUploadFilesContext({
    useUploadFileOperations () {
      const { sftpConnectionId } = useSftpFlowContext()
      return {
        uploadFile: (file) => uploadFile(
          sftpConnectionId.value,
          file.info.data.path,
          new axios.CancelToken((c) => {
            file.cancelUpload = c
          }))
      }
    },
    useHandleUpload () {
      const { handleNextStep } = useCurrentFlowContext()
      return {
        handleUpload: handleNextStep
      }
    }
  })
}
