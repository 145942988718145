<template>
  <step-card-block
    type="bright"
    :class="{ 'single-file-row--fail': isFail }"
    class="single-file-row"
  >
    <div class="single-file">
      <div class="single-file__info">
        <div class="file-info file-info--name">
          {{ name }}
        </div>
        <div class="file-info file-info--size">
          {{ byteToMB(size) }}
        </div>
        <div
          v-if="isUploading"
          class="progress"
        >
          <div
            :style="progressStyle"
            class="progress__bar"
          />
        </div>
      </div>
      <div class="single-file__status status">
        <a
          v-if="isUploading"
          class="link status__action"
          href="javascript:void(0)"
          @click="$emit('cancel-upload')"
        >
          {{ $t('button.cancel') }}
        </a>
        <svg-icon
          v-else
          class="status__icon"
          :class="isSuccess ? 'status__icon--success' : 'status__icon--fail'"
          :icon-class="isSuccess ? 'checked' : 'alert'"
        />
      </div>
    </div>
  </step-card-block>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { UploadStatus } from '../constants'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'

export default defineComponent({
  name: 'SingleFileStatusRow',
  components: {
    StepCardBlock
  },
  props: {
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: true,
      validator: (value) => value in UploadStatus
    },
    progress: {
      type: [Number, String],
      default: 'indeterminate',
      validator: (value) => (typeof value === 'string' && value === 'indeterminate') || (typeof value === 'number')
    }
  },
  setup (props) {
    const isUploading = computed(() => props.status === UploadStatus.UPLOADING)
    const isSuccess = computed(() => {
      return props.status === UploadStatus.SUCCESS
    })
    const isFail = computed(() => {
      return props.status === UploadStatus.FAIL
    })
    const progressStyle = computed(() => {
      if (props.progress === 'indeterminate') return {}

      return {
        transform: `translateX(${-50 + props.progress / 2}%) scaleX(${props.progress}%)`
      }
    })

    return {
      isUploading,
      isSuccess,
      isFail,
      progressStyle
    }
  }
})
</script>

<style lang="scss" scoped>
.single-file-row {
  background-color: rgba(67, 76, 76, 0.95);
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 20px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &--fail {
    color: #bdbdbd;
  }
}

.single-file {
  display: flex;

  &__info {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    letter-spacing: 0.5px;
    line-height: 19px;
    margin-right: 15px;
    position: relative;
  }

  &__status {
    position: relative;
    text-align: center;
    width: 52px;
  }

  .status {
    &__action {
      color: $theme-color-primary;
      letter-spacing: 0.5px;
      line-height: 19px;
    }

    &__icon {
      &--success {
        color: $theme-color-primary;
      }

      &--fail {
        color: #f1616d;
      }
    }
  }
}

.progress {
  flex-basis: 100%;
  height: 3px;
  margin: 8px 0 6px;

  &__bar {
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.2s;

    &::after {
      animation: progress-translate-x 1s ease-in-out infinite forwards;
      background: linear-gradient(90deg, #4ce2f0 0%, #438af8 25%, #4ce2f0 50%, #438af8 75%, #4ce2f0 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 200%;
    }
  }
}

@keyframes progress-translate-x {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0);
  }
}
</style>
