import { ref, computed } from '@vue/composition-api'
import { FileType, FileTypeMap } from '../../../constants'
import { useImportFlowContext, isLocalFileFlowContext, isSftpFlowContext } from '../../../composable'
import { UploadStatus } from '@/modules/shared/flow/steps/stepUploadFiles/constants'

/**
 * @typedef {object} Dependencies
 * @property {() => UseAnalyzeFileOperations} useAnalyzeFileOperations
 */

/**
 * @typedef {object} UseAnalyzeFileOperations
 * @property {(fileId, fileInfo) => Promise<any>} analyzeFile
 */

/**
 * @param {Dependencies}
 */
export function createBasicConfirmSheetsContext ({
  useAnalyzeFileOperations
}) {
  const flowContext = useImportFlowContext()
  if (!isSftpFlowContext(flowContext) && !isLocalFileFlowContext(flowContext)) {
    throw new Error('Current flow is not an sftp flow or a local file flow')
  }

  const {
    dataSourceId,
    uploadedFileList,
    handleNextStep,
    handleJumpStep,
    handleSaveResult,
    handleExit
  } = flowContext
  const { analyzeFile } = useAnalyzeFileOperations()

  const selectedList = ref([])
  function handleUpdateSelectedList (value) {
    selectedList.value = value
  }

  const successList = computed(() => uploadedFileList.value.filter((file) => file.status === UploadStatus.SUCCESS))
  const successResultList = computed(() => {
    return successList.value.map((file) => ({
      type: FileTypeMap[file.result.fileType],
      name: file.info.name,
      size: file.info.size,
      tabDetails: file.result.tabDetails,
      status: file.status
    }))
  })
  const failList = computed(() => uploadedFileList.value.filter((file) => file.status !== UploadStatus.SUCCESS))
  const failResultList = computed(() => {
    return failList.value.map((file) => ({
      type: file.info.type,
      name: file.info.name,
      size: file.info.size,
      tabDetails: [],
      status: file.status
    }))
  })

  const selectedResult = computed(() => {
    return selectedList.value
      .flatMap(([type, ...tabsIndex], index) => {
        const file = successList.value[index]
        const basic = {
          sftpPath: file.info.data.path,
          fileId: file.result.fileId,
          fileName: file.result.fileName,
          fileType: file.result.fileType
        }
        return {
          [FileType.CSV]: [basic],
          [FileType.EXCEL]: tabsIndex.map((tabIndex) => ({
            ...basic,
            tabDetail: file.result.tabDetails[tabIndex]
          }))
        }[type] ?? [null]
      })
      .filter((item) => item !== null)
  })

  const isLoading = ref(false)
  async function analyzeFiles () {
    const resultList = await Promise.all(
      selectedResult.value
        .map(async ({ sftpPath, fileId, fileName, fileType, tabDetail }) => {
          const fileInfo = {
            dataSourceId: dataSourceId.value,
            sftpPath,
            fileName,
            fileType,
            tabDetail
          }
          return await analyzeFile(fileId, fileInfo)
        })
    )

    return resultList
      .map((result) => {
        return {
          ...result,
          dataSourceId: dataSourceId.value
        }
      })
  }

  function handleClickPrevStep () {
    handleJumpStep('SelectFiles')
  }

  async function handleClickNextStep () {
    try {
      isLoading.value = true
      const analyzedResultList = await analyzeFiles()
      handleSaveResult({
        analyzedResultList
      })
      handleNextStep()
    } catch (error) {
      console.log(error)
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    handleClickPrevStep,
    handleClickNextStep,
    handleExit,
    successResultList,
    failResultList,
    handleUpdateSelectedList,
    selectedResult
  }
}
