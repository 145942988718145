import { ref } from '@vue/composition-api'
import { useSftpFlowContext } from '../../../composable'

export function setupStepSftpSelectFiles () {
  const {
    sftpConnectionId,
    handleSaveResult,
    handleNextStep,
    handlePrevStep,
    handleExit
  } = useSftpFlowContext()
  const selectedFiles = ref([])

  function handleClickNextStep () {
    /*
      type FileToUploadList = {
        name: string;
        data: {
          path: string;
        };
        size: number;
        type: FileType;
      }[]
      */
    handleSaveResult({
      fileToUploadList: selectedFiles.value
        .map(({ isFile, readPermission, path, ...rest }) => ({
          ...rest,
          data: {
            path
          }
        }))
    })
    handleNextStep()
  }

  return {
    sftpConnectionId,
    selectedFiles,
    handleClickNextStep,
    handlePrevStep,
    handleExit
  }
}
