<template>
  <div
    class="tree"
    :class="{ expanded: currentNode.isExpanded, disabled: currentNode[props.disabled] }"
    :data-level="currentNode.level"
    @click.stop="handleClickMainTree"
  >
    <slot
      v-if="currentNode.level > 0"
      name="node-template"
      v-bind="{ node: currentNode }"
    />
    <div
      v-if="currentNodeChildren.length > 0"
      class="tree__children"
    >
      <tree
        v-for="(child, index) in currentNodeChildren"
        :key="`tree-${currentNode.level}-child-${index}`"
        :props="props"
        :level="child.level"
        :parent="child.parent"
        :data="child[props.data]"
        :children="child[props.children]"
        :is-leaf="child[props.isLeaf]"
        :disabled="child[props.disabled]"
        :load-children="loadChildren"
        :is-loaded="child.isLoaded"
        :is-expanded="child.isExpanded"
      >
        <template #node-template="{ node }">
          <slot
            v-if="node.level > 0"
            name="node-template"
            v-bind="{ node }"
          />
        </template>
      </tree>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'Tree',
  props: {
    props: {
      type: Object,
      default: () => ({
        data: 'data',
        children: 'children',
        isLeaf: 'isLeaf',
        disabled: 'disabled'
      })
    },
    level: {
      type: Number,
      default: 0
    },
    parent: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    children: {
      type: Array,
      default: () => []
    },
    isLeaf: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loadChildren: {
      type: Function,
      default: () => (node, resolve) => []
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const currentNode = ref({
      level: props.level,
      parent: props.parent,
      [props.props.data]: props.data,
      [props.props.children]: props.children,
      [props.props.isLeaf]: props.isLeaf,
      [props.props.disabled]: props.disabled,
      isLoading: false,
      isLoaded: props.isLeaf
        ? true
        : props.isLoaded,
      isExpanded: props.isLeaf
        ? false
        : (props.level === 0 || props.isExpanded)
    })
    const currentNodeChildren = computed(() => currentNode.value[props.props.children])

    function transformChildren (currentNode, children) {
      return children.map((child) => {
        const childNode = {
          level: currentNode.level + 1,
          parent: currentNode,
          [props.props.data]: child[props.props.data],
          [props.props.children]: [],
          [props.props.isLeaf]: child[props.props.isLeaf],
          [props.props.disabled]: child[props.props.disabled],
          isLoading: child.isLoading ?? false,
          isLoaded: child[props.props.isLeaf]
            ? true
            : child.isLoaded ?? false,
          isExpanded: child[props.props.isLeaf]
            ? false
            : child.isExpanded ?? false
        }
        childNode[props.props.children] = transformChildren(childNode, child[props.props.children] ?? [])
        return childNode
      })
    }

    async function handleLoadChildren () {
      if ([
        currentNode.value[props.props.disabled],
        currentNode.value[props.props.isLeaf],
        currentNode.value.isLoading,
        currentNode.value.isLoaded
      ].some(Boolean)) return

      currentNode.value.isLoading = true
      const rawChildren = await new Promise((resolve) => {
        props.loadChildren(currentNode.value, resolve)
      })
      currentNode.value[props.props.children] = transformChildren(currentNode.value, rawChildren)
      currentNode.value.isLoading = false
      currentNode.value.isLoaded = true
    }
    function handleClickMainTree () {
      if ([
        currentNode.value[props.props.disabled],
        currentNode.value[props.props.isLeaf],
        currentNode.value.level === 0
      ].some(Boolean)) return

      currentNode.value.isExpanded = !currentNode.value.isExpanded
      handleLoadChildren()
    }

    onMounted(() => {
      if (currentNode.value.level === 0) {
        handleLoadChildren()
      }
    })

    return {
      currentNode,
      currentNodeChildren,
      handleClickMainTree
    }
  }
})
</script>
