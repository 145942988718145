import { ref } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import { Message } from 'element-ui'
import { useImportFlowContext } from '../../../composable'

function useFileSelectInput (refs) {
  const { t } = useI18n()

  const fileCountLimit = 1

  const acceptFileTypes = [

  ].join(',')

  /**
   * @typedef {
      {
        name: string;
        size: number;
        type: 'JSON';
        data: {
          formData: FormData
        };
      }
   * } File
   */
  /** @type {import('@vue/composition-api').Ref<File[]} */
  const selectedFiles = ref([])
  const isDragEnter = ref(false)

  function startSelectFile () {
    const uploadInput = refs.fileUploadInput
    uploadInput.value = ''
    uploadInput.click()
  }

  const { groupId } = useImportFlowContext()
  function handleSelectFile (inputFiles) {
    const files = Array.from(inputFiles ?? [])

    const entries = files
      .map(file => {
        // 用副檔名判斷過濾
        return [
          /\.jarvix$/.test(file.name),
          file
        ]
      })

    const invalidFilenameStr = entries
      .filter(([result]) => !result)
      .map(([, file]) => `"${file.name}"`)
      .join(', ')

    invalidFilenameStr.length && Message({
      message: t('message.fileTypeNotSupported', { fileType: invalidFilenameStr }),
      type: 'warning',
      duration: 3 * 1000,
      showClose: true
    })

    const validFiles = entries
      .filter(([result]) => result)
      .map(([, file]) => file)

    const firstFile = validFiles.shift()

    const formData = new FormData()
    formData.append('file', firstFile)
    formData.append('groupId', groupId.value)
    selectedFiles.value = [{
      name: firstFile.name,
      size: firstFile.size,
      type: 'JSON',
      data: {
        formData
      }
    }]
  }

  function dropFiles (event) {
    if (!event.dataTransfer.files) return

    const files = Array.from(event.dataTransfer.files)
    handleSelectFile(files)

    // 還原狀態
    isDragEnter.value = false
  }

  function handleDeleteSelectedFile (index) {
    selectedFiles.value.splice(index, 1)
  }

  return {
    fileCountLimit,
    acceptFileTypes,
    selectedFiles,
    startSelectFile,
    handleSelectFile,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile
  }
}

export function setupStep ({
  refs
}) {
  const {
    handleSaveResult,
    handleNextStep,
    handlePrevStep,
    handleExit
  } = useImportFlowContext()
  const {
    fileCountLimit,
    acceptFileTypes,
    selectedFiles,
    startSelectFile,
    handleSelectFile,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile
  } = useFileSelectInput(refs)

  function handleClickNextStep () {
    /*
      type FileToUploadList = {
        name: string;
        size: number;
        type: FileType;
        data: {
          formData: FormData;
        };
      }[]
      */
    handleSaveResult({
      fileToUploadList: selectedFiles.value.map(({ index, enable, msg, ...file }) => file)
    })
    handleNextStep()
  }

  return {
    fileCountLimit,
    acceptFileTypes,
    selectedFiles,
    startSelectFile,
    handleSelectFile,
    handleClickNextStep,
    handlePrevStep,
    handleExit,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile
  }
}
