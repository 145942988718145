import { useI18n } from '@/utils/composable/i18n'
import { createBasicImportFlowContext } from './basic'

import StepEditName from '../steps/stepEditName/StepEditName.vue'
import StepSelectFiles from '../steps/stepSelectFiles/StepSelectFiles.vue'
import StepUploadFiles from '@/modules/shared/flow/steps/stepUploadFiles/StepUploadFiles.vue'
import StepInputSetting from '../steps/stepInputSetting/StepInputSetting.vue'
import StepOutputSetting from '../steps/stepOutputSetting/StepOutputSetting.vue'

export const FLOW_ID = 'model-flow'

/** @param {{ emit: import('@vue/composition-api').SetupContext['emit'] }} */
export function createModelUploadFilesContext ({ emit }) {
  const { t } = useI18n()
  const basicImportFlowContext = createBasicImportFlowContext({
    emit,
    flowId: FLOW_ID,
    steps: [
      {
        key: 'EditName',
        title: () => t('model.upload.executionStep1'),
        component: StepEditName
      },
      {
        key: 'SelectFiles',
        title: () => t('editing.chooseFile'),
        component: StepSelectFiles
      },
      {
        key: 'UploadFiles',
        title: () => t('model.upload.executionStep1'),
        component: StepUploadFiles
      },
      {
        key: 'InputSetting',
        title: () => t('model.upload.executionStep1'),
        component: StepInputSetting
      },
      {
        key: 'OutputSetting',
        title: () => t('model.upload.executionStep1'),
        component: StepOutputSetting
      }
    ]
  })

  return {
    ...basicImportFlowContext
  }
}
