<template>
  <form class="sftp-connection-form">
    <!-- Avoid autofill -->
    <input
      class="hidden-input"
      name="sftp-username"
      type="text"
    >
    <input
      class="hidden-input"
      name="sftp-password"
      type="password"
    >
    <!-- ^^^Avoid autofill^^^ -->

    <input-block
      v-validate="'required'"
      v-model="formData.name"
      name="sftp-name"
      :label="$t('editing.connectionName')"
      :disabled="isLoading"
    />
    <div class="origin-input-block">
      <input-block
        v-validate="'required'"
        v-model="formData.host"
        name="sftp-host"
        label="Host"
        :disabled="isLoading"
      />
      <input-block
        v-validate="'required|positiveInt'"
        v-model="formData.port"
        name="sftp-port"
        label="Port"
        :disabled="isLoading"
      />
    </div>
    <input-block
      v-validate="'required'"
      v-model="formData.username"
      name="sftp-username"
      :label="$t('editing.userTitle')"
      :disabled="isLoading"
    />
    <input-block
      v-validate="'required'"
      v-model="formData.password"
      name="sftp-password"
      type="password"
      :label="$t('editing.userPassword')"
      :disabled="isLoading"
    />
  </form>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useValidator } from '@/utils/composable/validator'
import InputBlock from '@/components/InputBlock.vue'

export default defineComponent({
  name: 'SftpConnectionForm',
  components: {
    InputBlock
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const validator = useValidator()
    const initFormData = JSON.parse(
      JSON.stringify(props.initData?.connectionData ?? null)
    )
    if (initFormData) {
      initFormData.port = String(initFormData.port)
    }
    const formData = ref(initFormData ?? {
      name: '',
      host: '',
      port: '',
      username: '',
      password: ''
    })

    async function getFormData () {
      const valid = await validator.validateAll()
      if (!valid) return null
      return {
        ...formData.value,
        port: +formData.value.port
      }
    }

    return {
      formData,
      getFormData
    }
  }
})
</script>

<style lang="scss" scoped>
.hidden-input {
  border: 0;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0;
}

.input-block {
  margin-bottom: 3rem;
}

.origin-input-block {
  align-items: center;
  display: flex;
  margin-bottom: 3rem;

  .input-block {
    margin: 0;

    &:nth-of-type(1) {
      flex: 1;
      margin-right: 1rem;
    }

    &:nth-of-type(2) {
      flex-basis: 80px;
    }
  }
}
</style>
