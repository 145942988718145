<template>
  <div>
    <a
      href="javascript:void(0);"
      class="link action-link return-action"
      @click="handleCancelEditConnection"
    >
      <svg-icon icon-class="arrow-left" />
      <span>{{ $t('etl.returnConnectionHistory') }}</span>
    </a>
    <component
      ref="form"
      :is="formComponent"
      :init-data="editingConnection"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useStepSetupConnectionContext } from '../composable'

export default defineComponent({
  name: 'EditConnection',
  setup (props, { refs }) {
    const {
      isLoading,
      editingConnection,
      handleCancelEditConnection,
      formComponent
    } = useStepSetupConnectionContext()

    async function getFormData () {
      return await refs.form.getFormData()
    }

    return {
      isLoading,
      editingConnection,
      handleCancelEditConnection,
      formComponent,
      getFormData
    }
  }
})
</script>

<style lang="scss" scoped>

.return-action {
  align-items: center;
  display: flex;
  margin-bottom: 3rem;
  padding: 8px 0;

  .svg-icon {
    height: 0.75em;
    margin-right: 4px;
    width: 0.75em;
  }
}

</style>
