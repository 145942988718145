import { useI18n } from '@/utils/composable/i18n'
import { createBasicSetupConnectionContext } from './basic'
import {
  createDatabaseConnection as createDbConnection,
  getConnectionInfoList as getDbConnections,
  updateDatabaseConnection as updateDbConnection,
  deleteDatabaseConnection as deleteDbConnection,
  testConnection as testDbConnection,
  testOldConnection as testExistedDbConnection
} from '@/API/RemoteDbConnection'
import DbConnectionForm from '../components/DbConnectionForm.vue'

/**
 * @returns {import('./basic').UseConnectionOperations}
 */
function useConnectionOperations () {
  return {
    testNewConnection: testDbConnection,
    testExistedConnection: testExistedDbConnection,
    createConnection: createDbConnection,
    getConnections: getDbConnections,
    updateConnection: updateDbConnection,
    deleteConnection: deleteDbConnection
  }
}

/**
 * @returns {import('./basic').UseConnectionForm}
 */
function useConnectionForm () {
  return {
    formComponent: DbConnectionForm
  }
}

/**
 * @returns {import('./basic').UseConnectionInfoFormatter}
 */
function useConnectionInfoFormatter () {
  const { t } = useI18n()
  return {
    formatInfo: (connection) => {
      return {
        title: connection.name,
        entries: [
          `${t('editing.loginAccount')}: ${connection.account}`,
          `Schema: ${connection.schema}`,
          `${t('editing.databaseType')}: ${connection.databaseType}`,
          `Host: ${connection.host}`,
          `Database: ${connection.database}`,
          `Port: ${connection.port}`
        ]
      }
    }
  }
}

/**
 * @param {{ refs: import('@vue/composition-api').SetupContext['refs'] }}
 */
export function createDbSetupConnectionContext ({ refs }) {
  return createBasicSetupConnectionContext({
    refs,
    useConnectionOperations,
    useConnectionForm,
    useConnectionInfoFormatter
  })
}
