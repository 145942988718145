var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('step-card-block',{staticClass:"sftp-file-explorer",attrs:{"type":"bright"}},[(_vm.isLoadingRoot)?_c('div',{staticClass:"spinner"},[_c('svg-icon',{attrs:{"icon-class":"spinner"}})],1):_vm._e(),_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingRoot),expression:"!isLoadingRoot"}],ref:"form"},[_c('tree',{attrs:{"props":_vm.treeProps,"load-children":_vm.loadNode},scopedSlots:_vm._u([{key:"node-template",fn:function(ref){
var _obj;

var node = ref.node;return [_c('div',{staticClass:"file-item",class:{
            'is-file': node.isFile,
            disabled: node.disabled,
            expanded: node.isLoading ? false : node.isExpanded
          }},[(node.isFile)?[_c('label',{staticClass:"checkbox",attrs:{"for":node.data.path}},[_c('div',{staticClass:"checkbox-label"},[_c('input',{attrs:{"type":"checkbox","id":node.data.path,"name":node.data.path,"disabled":node.disabled},on:{"input":_vm.updateSelectedFiles}}),_c('div',{staticClass:"checkbox-square"})]),_c('svg-icon',{attrs:{"icon-class":( _obj = {}, _obj[_vm.FileType.CSV] = 'file-csv', _obj[_vm.FileType.EXCEL] = 'file-xlsx', _obj )[node.data.type]}}),_c('span',[_vm._v(_vm._s(node.data.name)+" ("+_vm._s(_vm.byteToMB(node.data.size))+")")])],1)]:[_c('svg-icon',{attrs:{"icon-class":(node.isLoading ? false : node.isExpanded) ? 'arrow-down2' : 'arrow-right'}}),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(node.isLoading),expression:"node.isLoading"}],staticClass:"spinner",attrs:{"icon-class":"spinner"}}),_c('svg-icon',{attrs:{"icon-class":(node.isLoading ? false : node.isExpanded) ? 'folder-open' : 'folder'}}),_c('span',[_vm._v(_vm._s(node.data.name))])]],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }