import { computed } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import { createBasicImportFlowContext } from './basic'

import StepLocalFileSelectFiles from '../steps/stepLocalFileSelectFiles/StepLocalFileSelectFiles.vue'
import StepUploadFiles from '@/modules/shared/flow/steps/stepUploadFiles/StepUploadFiles.vue'
import StepConfirmSheets from '../steps/stepConfirmSheets/StepConfirmSheets.vue'
import StepProcessData from '../steps/stepProcessData/StepProcessData.vue'

export const FLOW_ID = 'local-file-flow'

/** @param {{ emit: import('@vue/composition-api').SetupContext['emit'] }} */
export function createLocalFileFlowContext ({ emit }) {
  const { t } = useI18n()
  const basicImportFlowContext = createBasicImportFlowContext({
    emit,
    flowId: FLOW_ID,
    steps: [
      {
        key: 'SelectFiles',
        title: () => t('editing.chooseFile'),
        component: StepLocalFileSelectFiles
      },
      {
        key: 'UploadFiles',
        title: () => t('editing.uploadFile'),
        component: StepUploadFiles
      },
      {
        key: 'ConfirmSheets',
        title: () => t('editing.chooseDataSheet'),
        component: StepConfirmSheets
      },
      {
        key: 'ProcessData',
        title: () => t('editing.dataProcessing'),
        component: StepProcessData
      }
    ]
  })

  const { stepResultList } = basicImportFlowContext
  const fileToUploadList = computed(() => {
    return stepResultList.value[0]?.fileToUploadList ?? []
  })
  const uploadedFileList = computed(() => {
    return stepResultList.value[1]?.uploadedFileList ?? []
  })
  const analyzedResultList = computed(() => {
    return stepResultList.value[2]?.analyzedResultList ?? []
  })

  return {
    ...basicImportFlowContext,
    fileToUploadList,
    uploadedFileList,
    analyzedResultList
  }
}
