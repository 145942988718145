import { Fzf } from 'fzf'
import { computed, ref, onMounted } from '@vue/composition-api'
import { useDbFlowContext } from '../../../composable'
import {
  getTableList,
  analysisCreateTableSql as analyzeSqlTable,
  analyzeTable as analyzeDbTable
} from '@/API/RemoteDbConnection'
import { TableType } from '../../../constants'

/**
 * @typedef {object} Table
 * @property {keyof TableType} type
 * @property {string} name
 * @property {string} [sql]
 */

/**
 * @param {Table} table
 */
function defineTable (table) {
  return table
}

function useTableList () {
  const { dbConnectionId, dataSourceId } = useDbFlowContext()
  const tableList = ref([])
  const tableNameIndexMap = computed(() => {
    return Object.fromEntries(tableList.value.map((item, index) => [item.name, index]))
  })
  const selectedTableNameList = ref([])
  const selectedTableList = computed(() => {
    return selectedTableNameList.value.map((name) => {
      return tableList.value[tableNameIndexMap.value[name]]
    })
  })
  const tableConnectionLimit = 10
  const queryString = ref('')
  const fzf = computed(() => {
    return new Fzf(tableList.value, {
      selector: (item) => item.name
    })
  })
  const filteredTableList = computed(() => {
    if (!(queryString.value.trim())) {
      return tableList.value
    }
    const entries = fzf.value.find(queryString.value)
    return entries.map((entry) => entry.item)
  })

  const isLoading = ref(false)
  async function loadTableList () {
    isLoading.value = true
    tableList.value = (await getTableList(dbConnectionId.value))
      .map((tableName) => defineTable({
        type: TableType.DB,
        name: tableName
      }))
    isLoading.value = false
  }

  onMounted(() => {
    loadTableList()
  })

  const _isEditingSql = ref(false)
  const editingTableName = ref(null)
  const editingItem = computed(() => tableList.value[tableNameIndexMap.value[editingTableName.value]] ?? null)
  const isEditingSql = computed({
    get () {
      return _isEditingSql.value || !!editingItem.value
    },
    set (value) {
      _isEditingSql.value = value
      if (!value) editingTableName.value = null
    }
  })
  function handleAddSqlTable ({ name, sql }) {
    const table = defineTable({
      type: TableType.SQL,
      name,
      sql
    })
    tableList.value.unshift(table)
    selectedTableNameList.value.push(name)
    isEditingSql.value = false
  }
  function handleUpdateEditingItem ({ name, sql }) {
    if (editingItem.value === null) return
    const index = tableNameIndexMap.value[editingTableName.value]
    tableList.value.splice(index, 1, defineTable({
      type: TableType.SQL,
      name,
      sql
    }))
    isEditingSql.value = false
  }

  async function analyzeTables () {
    const analyzeTableApiMap = {
      [TableType.DB]: (table) => analyzeDbTable(dbConnectionId.value, dataSourceId.value, table.name),
      [TableType.SQL]: (table) => analyzeSqlTable(dbConnectionId.value, {
        creatingSql: table.sql,
        creatingSqlName: table.name,
        dataSourceId: dataSourceId.value
      })
    }
    const resultList = await Promise.all(
      selectedTableList.value.map(async (table) => {
        return await analyzeTableApiMap[table.type](table)
      })
    )
    return resultList.map((result) => ({
      ...result,
      dataSourceId: dataSourceId.value
    }))
  }

  return {
    tableConnectionLimit,
    isLoading,
    tableList,
    filteredTableList,
    selectedTableList,
    selectedTableNameList,
    isEditingSql,
    editingTableName,
    editingItem,
    handleAddSqlTable,
    handleUpdateEditingItem,
    analyzeTables,
    queryString
  }
}

export function setupStepDbSelectTable () {
  const {
    dbConnectionId,
    handleNextStep,
    handlePrevStep,
    handleSaveResult,
    handleExit
  } = useDbFlowContext()
  const {
    tableConnectionLimit,
    isLoading,
    tableList,
    queryString,
    filteredTableList,
    selectedTableList,
    selectedTableNameList,
    isEditingSql,
    editingTableName,
    editingItem,
    handleAddSqlTable,
    handleUpdateEditingItem,
    analyzeTables
  } = useTableList()

  const isProcessing = ref(false)
  async function handleClickNextStep () {
    try {
      isProcessing.value = true
      const analyzedResultList = await analyzeTables()
      handleSaveResult({
        analyzedResultList
      })
      handleNextStep()
    } catch (error) {
      console.error(error)
    } finally {
      isProcessing.value = false
    }
  }

  return {
    dbConnectionId,
    isLoading,
    isProcessing,
    handlePrevStep,
    handleClickNextStep,
    handleExit,
    tableConnectionLimit,
    tableList,
    queryString,
    filteredTableList,
    selectedTableList,
    selectedTableNameList,
    isEditingSql,
    editingTableName,
    editingItem,
    handleAddSqlTable,
    handleUpdateEditingItem
  }
}
