import { analyzeFile } from '@/API/RemoteSftpConnection'
import { useSftpFlowContext } from '../../../composable'
import { createBasicConfirmSheetsContext } from './basic'

/**
 * @returns {import('./basicalyzeFileOperations}
 */
function useAnalyzeFileOperations () {
  const { sftpConnectionId } = useSftpFlowContext()
  return {
    analyzeFile: (fileId, fileInfo) => analyzeFile(
      sftpConnectionId.value,
      fileId,
      fileInfo
    )
  }
}

export function createSftpConfirmSheetsContext () {
  return createBasicConfirmSheetsContext({
    useAnalyzeFileOperations
  })
}
