<template>
  <div class="file-result-list">
    <single-file-result-row
      v-for="(fileResult, index) in fileResultList"
      :key="`file-item-${String(index)}`"
      :value="selectedList[index]"
      v-bind="fileResult"
      @input="handleUpdateSelectedList(index, $event)"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import SingleFileResultRow from './SingleFileResultRow.vue'

export default defineComponent({
  name: 'FileResultList',
  components: {
    SingleFileResultRow
  },
  props: {
    fileResultList: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    const selectedList = ref(props.fileResultList.map(() => ([])))

    watch(selectedList, (value) => {
      emit('update-selected-list', value)
    })

    function handleUpdateSelectedList (index, value) {
      if (value === value.length) {
        selectedList.value.splice(index, 1)
        return
      }
      selectedList.value.splice(index, 1, value)
    }

    return {
      selectedList,
      handleUpdateSelectedList
    }
  }
})
</script>

<style lang="scss" scoped>
.file-result-list {
  max-height: 400px;
  overflow: auto;
}
</style>
