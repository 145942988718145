<template>
  <div class="history-connection-info-list">
    <div
      v-if="connectionInfoList.length === 0"
      class="no-element-result"
    >
      <slot name="empty-list-result">
        <step-card-block type="dark">
          <div class="no-element-result__text">
            {{ $t('etl.emptyConnectionHistory') }}
          </div>
        </step-card-block>
      </slot>
    </div>
    <div
      v-else
      class="connection-list"
      :class="{ disabled: isConnecting }"
    >
      <div
        v-for="(connectionInfo, index) in connectionInfoList"
        :key="`connection-info-${String(index)}`"
        class="connection-info"
      >
        <slot
          name="connection-info-content"
          :index="index"
          :connection-info="connectionInfo"
        >
          <step-card-block
            type="bright"
            @click="isConnecting || $emit('click-connection', index)"
          >
            <div
              v-if="connectingConnectionIndex === index"
              class="connecting-mask"
            >
              <spinner
                :title="$t('button.connecting')"
                size="20"
              />
              <button
                class="btn btn-outline"
                @click.stop="$emit('cancel-connect')"
              >
                {{ $t('button.cancelConnect') }}
              </button>
            </div>
            <div class="connection-info-content">
              <div class="connection-info-content__left">
                <div class="content__title">
                  {{ connectionInfo.title }}
                </div>
                <div class="content__body">
                  <div
                    v-for="(entry, index) in connectionInfo.entries"
                    :key="`info-entry-${String(index)}`"
                    class="info-item"
                  >
                    {{ entry }}
                  </div>
                </div>
              </div>
              <div class="connection-info-content__right">
                <a
                  href="javascript:void(0);"
                  class="link action-link"
                  @click.stop="isConnecting || $emit('edit-connection', index)"
                >
                  {{ $t('button.edit') }}
                </a>
                <a
                  href="javascript:void(0);"
                  class="btn-delete"
                  @click.stop="isConnecting || handleClickDelete(index)"
                >
                  <svg-icon icon-class="delete" />
                </a>
              </div>
            </div>
            <tooltip-dialog
              v-if="index === currentDeleteIndex"
              :msg="$t('editing.toolTipMessage.comfrmToDeleteConnectionHistory')"
              class="tooltip-dialog"
              @cancel="handleExecResolver(false)"
              @confirm="handleExecResolver(true)"
            />
          </step-card-block>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import TooltipDialog from '@/components/dialog/TooltipDialog'

const noop = () => {}

export default defineComponent({
  name: 'HistoryConnectionInfoList',
  components: {
    StepCardBlock,
    TooltipDialog
  },
  props: {
    connectionInfoList: {
      type: Array,
      required: true
    },
    connectingConnectionIndex: {
      type: Number,
      default: -1
    }
  },
  setup (props, { emit }) {
    const currentDeleteIndex = ref(-1)
    let confirmDeleteResolver = noop
    const isConnecting = computed(() => currentDeleteIndex.value !== -1)

    async function handleClickDelete (index) {
      currentDeleteIndex.value = index
      const promise = new Promise(resolve => confirmDeleteResolver = resolve)
      // tooltip
      const result = await promise
      if (result) {
        emit('delete-connection', index)
      }
      currentDeleteIndex.value = -1
      confirmDeleteResolver = noop
    }

    function handleExecResolver (value) {
      confirmDeleteResolver(value)
    }

    return {
      currentDeleteIndex,
      isConnecting,
      handleClickDelete,
      handleExecResolver
    }
  }
})
</script>

<style lang="scss" scoped>
.history-connection-info-list {
  .no-element-result {
    &__text {
      color: #aaa;
      text-align: center;
    }
  }

  .connection-list {
    max-height: 400px;
    overflow: auto;

    .connection-info {
      margin-bottom: 1rem;

      .step-card-block {
        border: 1px solid transparent;
        border-radius: 12px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        transition: all 0.1s;

        &:hover {
          border-color: $theme-text-color-primary;
        }

        .connecting-mask {
          align-items: center;
          background-color: rgba(72, 84, 84, 0.8);
          display: flex;
          height: 100%;
          justify-content: space-between;
          left: 0;
          padding: 0 2rem;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;

          ::v-deep .spinner-block {
            padding: 0;

            .spinner-container {
              align-items: center;
              display: flex;

              .spinner-circle {
                margin-right: 1rem;
              }

              .spinner-title {
                margin: 0;
              }
            }
          }
        }

        .connection-info-content {
          display: flex;

          &__left {
            flex: 1;

            .content {
              &__title {
                font-size: 18px;
                font-weight: 600;
              }

              &__body {
                display: flex;
                flex-wrap: wrap;

                .info-item {
                  color: #ccc;
                  flex-basis: 50%;
                  font-size: 14px;
                  padding: 0.25rem 0;
                  padding-right: 1rem;
                }
              }
            }
          }

          &__right {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .btn-delete {
              color: #fff;
            }
          }
        }

        .tooltip-dialog {
          bottom: 53px;
          right: 12px;

          &::after {
            border-bottom: 9px solid transparent;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 9px solid #2ad2e2;
            content: '';
            position: absolute;
            right: 6%;
            top: 100%;
          }
        }
      }
    }

    &.disabled .connection-info .step-card-block {
      background-color: rgba(72, 84, 84, 0.5);
      cursor: not-allowed;

      &:hover {
        border-color: transparent;
      }

      .connection-info-content {
        opacity: 0.3;
      }
    }
  }
}
</style>
