import axios from 'axios'
import { uploadModel as uploadFile } from '@/API/Model'
import { createBasicUploadFilesContext } from '@/modules/shared/flow/steps/stepUploadFiles/composable/basic'
import { useCurrentFlowContext } from '@/modules/shared/flow/composable/flow'

export function createModelUploadFilesContext () {
  return createBasicUploadFilesContext({
    useUploadFileOperations () {
      return {
        uploadFile: (file) => uploadFile(
          file.info.data.formData,
          (percentage) => {
            file.progress = percentage
          },
          new axios.CancelToken((c) => {
            file.cancelUpload = c
          }))
      }
    },
    useHandleUpload () {
      const { handleNextStep } = useCurrentFlowContext()
      return {
        handleUpload: handleNextStep
      }
    }
  })
}
