<template>
  <div>
    <data-frame-list
      ref="dataFrameListRef"
      :group-id="groupId"
      :original-data-frame-list="uploadedImportData.dataSettings.dataFrameList"
      :disabled="isPending"
      @finish="statusOfFinish.splice(0, 1, true)"
      @unfinish="statusOfFinish.splice(0, 1, false)"
    />
    <model-list
      v-if="uploadedImportData.dataSettings.modelList.length"
      ref="modelListRef"
      :group-id="groupId"
      :original-model-list="uploadedImportData.dataSettings.modelList"
      :disabled="isPending"
      @finish="statusOfFinish.splice(1, 1, true)"
      @unfinish="statusOfFinish.splice(1, 1, false)"
    />
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        :disabled="isPending"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        class="btn btn-outline"
        :disabled="isPending"
        @click="handlePrevStep"
      >
        {{ $t('button.prevStep') }}
      </button>
      <button
        class="btn btn-default"
        :disabled="!isFinished || isPending"
        @click="handleClickBuild"
      >
        {{ $t('button.build') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { setupStep } from './composable'

import DataFrameList from './components/DataFrameList.vue'
import ModelList from './components/ModelList.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'

export default defineComponent({
  name: 'StepConfigureDataSettings',
  components: {
    DataFrameList,
    ModelList,
    StepFooterButtonListBlock
  },
  setup () {
    return setupStep()
  }
})
</script>
