import { useMapGetters } from '@/utils/composable/vuex'
import { createBasicFlowContext } from '@/modules/shared/flow/composable/flow'

/**
 * @typedef {object} Dependencies
 * @property {EmitFn} emit
 */

/**
 * @param {Dependencies}
 */
function useCommon ({
  emit
}) {
  const { groupId } = useMapGetters('userManagement', {
    groupId: 'getCurrentGroupId'
  })
  function handleDone () {
    emit('done')
  }
  function handleExit () {
    emit('exit')
  }

  return {
    groupId,
    handleDone,
    handleExit
  }
}

/**
 * @typedef {import('../../../shared/flow/composable/flow').BasicFlowOptions} BasicFlowOptions
 * @typedef {import('@vue/composition-api').SetupContext['emit']} EmitFn
 * @typedef {BasicFlowOptions & { emit: EmitFn }} BasicImportFlowOptions
 */

/** @param {BasicImportFlowOptions} */
export function createBasicImportFlowContext ({ emit, ...basicFlowOptions }) {
  const basicFlowContext = createBasicFlowContext(basicFlowOptions)
  const common = useCommon({ emit })

  return {
    ...basicFlowContext,
    ...common
  }
}
