<template>
  <div>
    <step-card-block>
      <uploading-file-list
        :uploading-file-list="uploadingFileList"
        @cancel-upload="handleCancelUpload"
      />
    </step-card-block>
    <div
      v-if="isUploading"
      class="warning-text"
    >
      {{ $t('editing.dataTransferWarning') }}
    </div>
    <step-footer-button-list-block v-else>
      <button
        class="btn btn-outline"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        class="btn btn-outline"
        @click="handlePrevStep"
      >
        {{ $t('button.prevStep') }}
      </button>
      <button
        v-if="hasAtLeastOneSuccess"
        class="btn btn-default"
        @click="handleNextStep"
      >
        {{ $t('button.nextStep') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import UploadingFileList from './components/UploadingFileList.vue'
import { setupStepUploadFiles } from './composable'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    UploadingFileList
  },
  setup () {
    return setupStepUploadFiles()
  }
})
</script>
<style lang="scss" scoped>
.step-card-block {
  margin-bottom: 1rem;
}

.warning-text {
  color: #ffdf6f;
  font-size: 14px;
  text-align: end;
}
</style>
