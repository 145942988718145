import axios from 'axios'
import { ref, computed } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import { useImportFlowContext } from '../../../composable'
import CreateSftpConnection from '../components/CreateConnection.vue'
import EditSftpConnection from '../components/EditConnection.vue'
import SelectSftpConnection from '../components/SelectConnection.vue'

const Mode = {
  CREATE_CONNECTION: 0,
  SELECT_CONNECTION: 1,
  EDIT_CONNECTION: 2
}

const noop = () => {}

/**
 * @typedef {object} Dependencies
 * @property {import('@vue/composition-api').SetupContext['refs']} refs
 * @property {() => UseConnectionOperations} useConnectionOperations
 * @property {() => UseConnectionForm} useConnectionForm
 * @property {() => UseConnectionInfoFormatter} useConnectionInfoFormatter
 * @property {UseCurrentFlowContextFn} useCurrentFlowContext
 */

/**
 * @typedef {object} UseConnectionInfoFormatter
 * @property {(connection) => { title: string; entries: string[] }} formatInfo
 */

/**
 * @typedef {object} UseConnectionOperations
 * @property {(connectionInfo) => Promise<any>} testNewConnection
 * @property {(connectionId, cancelToken) => Promise<any>} testExistedConnection
 * @property {(connectionInfo) => Promise<any>} createConnection
 * @property {(groupId) => Promise<any>} getConnections
 * @property {(connectionId, connectionInfo) => Promise<any>} updateConnection
 * @property {(connectionId) => Promise<any>} deleteConnection
 */

/**
 * @typedef {object} UseConnectionForm
 * @property {ReturnType<typeof import('@vue/composition-api').defineComponent>} formComponent
 */

/**
 * @param {Dependencies}
 * @returns
 */
export function createBasicSetupConnectionContext ({
  refs,
  useConnectionOperations,
  useConnectionForm,
  useConnectionInfoFormatter
}) {
  const {
    handleNextStep,
    handleSaveResult,
    handleExit,
    stepResultList,
    currentStepIndex,
    groupId
  } = useImportFlowContext()
  const {
    testNewConnection,
    testExistedConnection,
    createConnection,
    getConnections,
    updateConnection,
    deleteConnection
  } = useConnectionOperations()
  const { formComponent } = useConnectionForm()
  const { formatInfo } = useConnectionInfoFormatter()

  const mode = ref(Mode.SELECT_CONNECTION)
  const isLoading = ref(false)
  const lastConnection = computed(() => {
    return stepResultList.value[currentStepIndex.value] ?? null
  })
  const editingConnection = ref(null)
  const isConnectCanceled = ref(false)
  let cancelConnectRequest = noop

  async function handleConnect (connectionId = null) {
    // 處理新連線設定模式下點連線按鈕 or 選連線紀錄模式下點紀錄
    isLoading.value = true
    let hasError = false
    const result = {
      connectionId,
      connectionData: null
    }

    if (connectionId === null) {
      const modeComponentInstance = refs.modeComponent
      const connectionData = await modeComponentInstance.getFormData()

      // 新連線設定模式下點連線按鈕
      try {
        if (connectionData === null) {
          throw new Error()
        }

        const fullConnectionData = {
          ...connectionData,
          groupId: groupId.value
        }

        await testNewConnection(fullConnectionData)
        result.connectionId = await createConnection(fullConnectionData)
        result.connectionData = fullConnectionData
      } catch {
        hasError = true
      }
    } else {
      // 選連線紀錄模式下點紀錄
      try {
        await testExistedConnection(connectionId, new axios.CancelToken((c) => {
          cancelConnectRequest = c
        }))
      } catch {
        hasError = true
      }
    }

    if (isConnectCanceled.value) {
      isConnectCanceled.value = false
      return
    }

    if (hasError) {
      isLoading.value = false
      isConnectCanceled.value = false
      return
    }

    isLoading.value = false
    handleSaveResult(result)
    handleNextStep()
  }
  function handleCancelConnect () {
    cancelConnectRequest()
    isLoading.value = false
    isConnectCanceled.value = true
  }
  function handleSwitchMode () {
    mode.value = {
      [Mode.CREATE_CONNECTION]: Mode.SELECT_CONNECTION,
      [Mode.SELECT_CONNECTION]: Mode.CREATE_CONNECTION
    }[mode.value]
  }
  async function handleStartEditConnection (connection) {
    mode.value = Mode.EDIT_CONNECTION
    editingConnection.value = connection
  }
  async function handleEndEditConnection (isNeedConnect = false) {
    const modeComponentInstance = refs.modeComponent
    const connectionData = await modeComponentInstance.getFormData()

    if (connectionData === null) return

    const connectionId = editingConnection.value?.connectionId ?? null

    if (connectionId === null) return

    await updateConnection(connectionId, connectionData)

    if (!isNeedConnect) {
      editingConnection.value = null
      mode.value = Mode.SELECT_CONNECTION
      return
    }

    await handleConnect(connectionId)
  }
  function handleCancelEditConnection () {
    editingConnection.value = null
    mode.value = Mode.SELECT_CONNECTION
  }

  const { t } = useI18n()
  const currentModeTitle = computed(() => ({
    [Mode.CREATE_CONNECTION]: t('etl.newConnectionSetting'),
    [Mode.SELECT_CONNECTION]: t('etl.connectionHistory')
  }[mode.value]))
  const switchModeButtonText = computed(() => ({
    [Mode.CREATE_CONNECTION]: t('etl.connectionHistory'),
    [Mode.SELECT_CONNECTION]: t('etl.newConnectionSetting')
  }[mode.value]))
  const currentModeComponent = computed(() => ({
    [Mode.CREATE_CONNECTION]: CreateSftpConnection,
    [Mode.SELECT_CONNECTION]: SelectSftpConnection,
    [Mode.EDIT_CONNECTION]: EditSftpConnection
  }[mode.value]))

  return {
    Mode,
    mode,
    groupId,
    isLoading,
    currentModeTitle,
    switchModeButtonText,
    currentModeComponent,
    lastConnection,
    editingConnection,
    handleSwitchMode,
    handleEndEditConnection,
    handleConnect,
    handleCancelConnect,
    handleStartEditConnection,
    handleCancelEditConnection,
    handleExit,
    getConnections,
    deleteConnection,
    formComponent,
    formatInfo
  }
}
