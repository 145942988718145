import { defineFlowContext } from '@/modules/shared/flow/composable/flow'
import { createLocalFileFlowContext, FLOW_ID as LOCAL_FILE_FLOW_ID } from './localFileFlow'
import { createDbFlowContext, FLOW_ID as DB_FLOW_ID } from './dbFlow'
import { createSftpFlowContext, FLOW_ID as SFTP_FLOW_ID } from './sftpFlow'
import { ImportType } from '../constants'

/**
 * @typedef {object} ImportFlowContextOptions
 * @property {keyof ImportType} importType
 * @property {import('@vue/composition-api').SetupContext['emit']} emit
 */

export const {
  registerFlowContext: registerImportFlowContext,
  useFlowContext: useImportFlowContext
} = defineFlowContext({
  /** @param {ImportFlowContextOptions} */
  createFlowContext ({ importType, emit }) {
    const createContext = {
      [ImportType.LOCAL]: createLocalFileFlowContext,
      [ImportType.REMOTE_DB]: createDbFlowContext,
      [ImportType.REMOTE_SFTP]: createSftpFlowContext
    }[importType]

    return createContext({ emit })
  }
})

/** @type {(ctx: any) => ctx is ReturnType<typeof createLocalFileFlowContext>} */
export const isLocalFileFlowContext = (ctx) => ctx?.flowId === LOCAL_FILE_FLOW_ID
export function useLocalFileFlowContext () {
  const flowContext = useImportFlowContext()
  if (!isLocalFileFlowContext(flowContext)) {
    throw new Error('Current flow is not a local file flow')
  }
  return flowContext
}

/** @type {(ctx: any) => ctx is ReturnType<typeof createDbFlowContext>} */
export const isDbFlowContext = (ctx) => ctx?.flowId === DB_FLOW_ID
export function useDbFlowContext () {
  const flowContext = useImportFlowContext()
  if (!isDbFlowContext(flowContext)) {
    throw new Error('Current flow is not a db flow')
  }
  return flowContext
}

/** @type {(ctx: any) => ctx is ReturnType<typeof createSftpFlowContext>} */
export const isSftpFlowContext = (ctx) => ctx?.flowId === SFTP_FLOW_ID
export function useSftpFlowContext () {
  const flowContext = useImportFlowContext()
  if (!isSftpFlowContext(flowContext)) {
    throw new Error('Current flow is not an sftp flow')
  }
  return flowContext
}
