import { defineContext } from '@/utils/composable/context'
import { isDbFlowContext, isSftpFlowContext, useImportFlowContext } from '../../../composable'
import { createDbSetupConnectionContext } from './db'
import { createSftpSetupConnectionContext } from './sftp'

export const {
  registerContext: setupStepSetupConnection,
  useContext: useStepSetupConnectionContext
} = defineContext({
  namespace: 'context:step-setup-connection',
  /**
   * @param {{ refs: import('@vue/composition-api').SetupContext['refs'] }}
   */
  createContext ({ refs }) {
    const flowContext = useImportFlowContext()
    if (isDbFlowContext(flowContext)) {
      return createDbSetupConnectionContext({ refs })
    } else if (isSftpFlowContext(flowContext)) {
      return createSftpSetupConnectionContext({ refs })
    } else {
      throw new Error('"Step: SetupConnection" is not allowed in current flow')
    }
  }
})
