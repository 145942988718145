<template>
  <component
    ref="form"
    :is="formComponent"
    :init-data="lastConnection"
    :is-loading="isLoading"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useStepSetupConnectionContext } from '../composable'

export default defineComponent({
  name: 'CreateConnection',
  setup (props, { refs }) {
    const {
      isLoading,
      lastConnection,
      formComponent
    } = useStepSetupConnectionContext()

    async function getFormData () {
      return await refs.form.getFormData()
    }

    return {
      isLoading,
      lastConnection,
      formComponent,
      getFormData
    }
  }
})
</script>
