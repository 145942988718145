<template>
  <div class="single-file-row-container">
    <step-card-block
      type="bright"
      :class="{ 'single-file-row--fail': isFail }"
      class="single-file-row"
    >
      <div class="single-file">
        <div class="single-file__info">
          <div class="file-info file-info--name">
            {{ name }}
          </div>
          <div class="file-info file-info--size">
            {{ byteToMB(size) }}
          </div>
        </div>
        <div class="single-file__status">
          <a
            class="link action-link"
            href="javascript:void(0)"
            @click="$emit('delete')"
          >
            {{ $t('button.delete') }}
          </a>
        </div>
      </div>
    </step-card-block>
    <div
      v-if="isFail"
      class="fail-message"
    >
      <svg-icon
        class="fail-message__icon"
        icon-class="alert"
      />
      <span class="fail-message__text">
        {{ msg }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'

export default defineComponent({
  name: 'SingleFileStatusRow',
  components: {
    StepCardBlock
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    enable: {
      type: Boolean,
      required: true
    },
    msg: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const isFail = computed(() => {
      return !props.enable
    })

    return {
      isFail
    }
  }
})
</script>

<style lang="scss" scoped>
.single-file-row-container:not(:last-of-type) {
  margin-bottom: 1rem;
}

.single-file-row {
  background-color: rgba(67, 76, 76, 0.95);
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 20px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &--fail {
    color: #bdbdbd;
  }
}

.single-file {
  display: flex;

  &__info {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    letter-spacing: 0.5px;
    line-height: 19px;
    margin-right: 15px;
  }

  &__status {
    padding: 0 1rem;

    .action-link {
      color: $theme-color-primary;
      letter-spacing: 0.5px;
      line-height: 19px;
    }
  }
}

.fail-message {
  align-items: center;
  color: #f1616d;
  display: flex;

  &__icon {
    height: 20px;
    margin-right: 0.5rem;
    width: 20px;
  }
}

.progress {
  flex-basis: 100%;
  height: 3px;
  margin: 8px 0 6px;

  &__bar {
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
    position: relative;

    &::after {
      animation: progress-translate-x 1s ease-in-out infinite forwards;
      background: linear-gradient(90deg, #4ce2f0 0%, #438af8 25%, #4ce2f0 50%, #438af8 75%, #4ce2f0 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 200%;
    }
  }
}

@keyframes progress-translate-x {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0);
  }
}
</style>
