<template>
  <step-card-block
    type="bright"
    class="sftp-file-explorer"
  >
    <div
      v-if="isLoadingRoot"
      class="spinner"
    >
      <svg-icon icon-class="spinner" />
    </div>
    <form
      v-show="!isLoadingRoot"
      ref="form"
    >
      <tree
        :props="treeProps"
        :load-children="loadNode"
      >
        <template #node-template="{ node }">
          <div
            class="file-item"
            :class="{
              'is-file': node.isFile,
              disabled: node.disabled,
              expanded: node.isLoading ? false : node.isExpanded
            }"
          >
            <template v-if="node.isFile">
              <label
                class="checkbox"
                :for="node.data.path"
              >
                <div class="checkbox-label">
                  <input
                    type="checkbox"
                    :id="node.data.path"
                    :name="node.data.path"
                    :disabled="node.disabled"
                    @input="updateSelectedFiles"
                  >
                  <div class="checkbox-square" />
                </div>

                <svg-icon
                  :icon-class="{
                    [FileType.CSV]: 'file-csv',
                    [FileType.EXCEL]: 'file-xlsx'
                  }[node.data.type]"
                />
                <span>{{ node.data.name }} ({{ byteToMB(node.data.size) }})</span>
              </label>
            </template>
            <template v-else>
              <svg-icon :icon-class="(node.isLoading ? false : node.isExpanded) ? 'arrow-down2' : 'arrow-right'" />
              <svg-icon
                v-show="node.isLoading"
                class="spinner"
                icon-class="spinner"
              />
              <svg-icon :icon-class="(node.isLoading ? false : node.isExpanded) ? 'folder-open' : 'folder'" />
              <span>{{ node.data.name }}</span>
            </template>
          </div>
        </template>
      </tree>
    </form>
  </step-card-block>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { delay } from '@/utils/general'
import { getFolderDataByPath } from '@/API/RemoteSftpConnection'
import { FileType, FileTypeMap } from '../../../constants'
import Tree from './Tree.vue'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'

export default defineComponent({
  name: 'SftpFileExplorer',
  components: {
    StepCardBlock,
    Tree
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    sftpConnectionId: {
      type: Number,
      required: true
    }
  },
  setup (props, { refs, emit }) {
    const treeProps = {
      data: 'data',
      children: 'files',
      isLeaf: 'isFile',
      disabled: 'disabled'
    }
    const filesCache = ref([])
    const isLoadingRoot = ref(false)
    const selectedFiles = computed({
      get () {
        return props.value
      },
      set (value) {
        emit('input', value)
      }
    })
    const filesCacheMap = computed(() => {
      return Object.fromEntries(filesCache.value.map((file) => [file.path, file]))
    })

    watch(selectedFiles, () => {
      const list = selectedFiles.value.map((file) => file.path)
      Array.from(document.querySelectorAll('input[type="checkbox"]'))
        .map((input) => {
          input.checked = false
          return input
        })
        .forEach((input) => {
          if (list.includes(input.id)) input.checked = true
        })
    })

    async function readFolder (path) {
      await delay(500)
      const { currentPath, folderList, fileList } = await getFolderDataByPath(props.sftpConnectionId, path)

      const folder = {
        currentPath,
        files: [
          ...folderList.map((folder) => ({
            name: folder.name,
            path: `${currentPath}/${folder.name}`.replace(/\/+/g, '/'),
            isFile: false,
            type: null,
            size: null,
            readPermission: folder.readPermission,
            mTime: null
          })),
          ...fileList.map((file) => ({
            name: file.name,
            path: `${currentPath}/${file.name}`.replace(/\/+/g, '/'),
            isFile: true,
            type: FileTypeMap[file.name.split('.').pop()],
            size: file.size,
            readPermission: file.readPermission,
            mTime: file.mTime
          }))
        ]
      }

      filesCache.value.push(...folder.files.filter((file) => file.isFile))

      return folder
    }
    function transformToNode (data) {
      const { isFile } = data
      const result = {
        data: data,
        isFile,
        files: [],
        disabled: !data.readPermission
      }
      return result
    }
    async function getRootChild () {
      isLoadingRoot.value = true
      const result = await readFolder()
      isLoadingRoot.value = false
      return [{
        data: {
          name: result.currentPath.split('/').pop() || '/',
          path: result.currentPath,
          type: null,
          size: null,
          readPermission: true,
          mTime: null
        },
        files: result.files.map(transformToNode),
        isFile: false,
        isLoaded: true,
        isExpanded: true,
        disabled: false
      }]
    }
    async function loadNode (node, resolve) {
      if (node.level === 0) {
        return resolve(await getRootChild())
      }

      const result = await readFolder(node.data.path)
      return resolve(result.files.map(transformToNode))
    }
    function updateSelectedFiles () {
      const keys = Object.keys(Object.fromEntries(new FormData(refs.form)))
      selectedFiles.value = keys.map((key) => filesCacheMap.value[key])
    }

    return {
      FileType,
      treeProps,
      isLoadingRoot,
      loadNode,
      updateSelectedFiles
    }
  }
})
</script>

<style lang="scss" scoped>
.sftp-file-explorer {
  flex-basis: 60%;
  height: 100%;
  margin-right: 1.5rem;
  overflow: auto;

  .spinner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    .svg-icon {
      height: 30px;
      width: 30px;
    }
  }

  ::v-deep .tree {
    &.disabled {
      opacity: 0.3;
    }

    > .tree__children {
      display: inline-block;
    }

    &:not(.expanded) > .tree__children {
      display: none;
    }

    &:not([data-level='0']) > .tree__children {
      margin-left: 0.5rem;
      padding-left: 1rem;
    }

    &.expanded:not([data-level='0']):not([data-level='1']) > .tree-__hildren {
      border: 2px solid transparent;
      border-left-color: #515e5e;
    }
  }

  .file-item {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: 2rem;
    white-space: nowrap;

    &.disabled {
      &:not(.is-file),
      .checkbox {
        cursor: not-allowed;
      }
    }

    &.expanded {
      .svg-icon {
        color: $theme-color-primary;
      }
    }

    .svg-icon {
      height: 1rem;
      margin-right: 0.5rem;
      width: 1rem;

      &.spinner {
        height: 0.75rem;
        width: 0.75rem;
      }
    }

    &:not(.is-file),
    .checkbox {
      cursor: pointer;
    }

    .checkbox {
      align-items: center;
      display: flex;
      padding: 0 4px;

      &-label {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
