import { ref, computed } from '@vue/composition-api'
import { useMapState } from '@/utils/composable/vuex'
// import { useI18n } from '@/utils/composable/i18n'
// import { Message } from 'element-ui'
import { useModelUploadFilesContext } from '../../../composable'

function useFileSelectInput (props, refs) {
  // const { t } = useI18n()
  const {
    groupId
  } = useModelUploadFilesContext()
  const {
    userId
  } = useMapState('userManagement', ['userId'])
  // const { currentUploadModelName } = useMapState('modelManagement', ['currentUploadModelName'])
  // const { updateCurrentUploadModelInfo } = useMapMutations('modelManagement', ['updateCurrentUploadModelInfo'])

  const acceptFileTypes = [
    '*'
  ].join(',')
  const mainScriptName = 'main.py'

  const selectedFiles = ref([])
  const isDragEnter = ref(false)

  function startSelectFile () {
    const uploadInput = refs.fileUploadInput
    uploadInput.value = ''
    uploadInput.click()
  }

  function handleSelectFile (files) {
    if (!files) return
    for (let i = 0; i < files.length; i++) {
      let formData = new FormData()
      formData.append('model', files[i])
      if (!props.isReUpload) {
        formData.append('groupId', groupId)
        formData.append('userId', userId)
      } else {
        // 重新上傳檔案時，要告訴後端開啟新資料夾存放這些檔案
        // 目前做法是，第一支檔案 createDirectory 為 true
        // 其餘檔案 createDirectory 為 false，將直接加進當前資料夾
        formData.append('createDirectory', selectedFiles.value.length === 0)
      }
      selectedFiles.value.push({
        data: formData,
        size: files[i].size,
        groupId: groupId,
        fileFullName: files[i].name,
        id: new Date().getTime() + i
      })
    }
  }

  function dropFiles (event) {
    if (!event.dataTransfer.files) return
    const files = Array.from(event.dataTransfer.files)
    handleSelectFile(files)

    // 還原狀態
    isDragEnter.value = false
  }

  function handleDeleteSelectedFile (index) {
    selectedFiles.value.splice(index, 1)
  }

  // function hasMainPy () {
  //   const hasMainPy = selectedFiles.findIndex(element => {
  //     return element.fileFullName === 'main.py'
  //   })

  //   if (hasMainPy === -1) {
  //     Message({
  //       message: t('model.upload.lackOfMainScript', { mainScriptName: 'main.py' }),
  //       type: 'warning',
  //       duration: 3 * 1000,
  //       showClose: true
  //     })
  //     return false
  //   }
  //   return true
  // }

  return {
    mainScriptName,
    acceptFileTypes,
    selectedFiles,
    startSelectFile,
    handleSelectFile,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile
  }
}

export function setupStepSelectFiles (props, { refs }) {
  const {
    handleSaveResult,
    handleNextStep,
    handlePrevStep,
    handleExit
  } = useModelUploadFilesContext()
  const {
    mainScriptName,
    acceptFileTypes,
    selectedFiles,
    startSelectFile,
    handleSelectFile,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile
  } = useFileSelectInput(props, refs)
  // 總資料傳輸量 Bytes
  const totalTransmitDataBytes = computed(() => {
    return selectedFiles.value.reduce((acc, file) => {
      return acc + file.size
    }, 0)
  })

  function handleClickNextStep () {
    handleSaveResult({
      fileToUploadList: selectedFiles.value.map(({ index, enable, msg, ...file }) => file)
    })
    handleNextStep()
  }
  return {
    mainScriptName,
    selectedFiles,
    acceptFileTypes,
    startSelectFile,
    handleSelectFile,
    handleClickNextStep,
    handlePrevStep,
    handleExit,
    isDragEnter,
    dropFiles,
    handleDeleteSelectedFile,
    totalTransmitDataBytes
  }
}
