<template lang="">
  <div>
    <step-card-block class="process-data">
      <div
        v-if="isLoading || !canMountEtlComponent"
        class="spinner"
      >
        <svg-icon icon-class="spinner" />
        <span class="spinner__text">{{ $t('editing.duringDataProcessing') }}</span>
      </div>
      <etl-choose-column v-else />
    </step-card-block>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        class="btn btn-outline"
        @click="handlePrevStep"
      >
        {{ $t('button.prevStep') }}
      </button>
      <button
        v-if="hasAtLeastOneColumnPerTable"
        :disabled="isLoading"
        class="btn btn-default"
        @click="handleClickBuildData"
      >
        {{ $t('button.buildData') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import EtlChooseColumn from '@/pages/dataManagement/components/etl/EtlChooseColumn.vue'
import { setupStepProcessData } from './composable'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    EtlChooseColumn
  },
  setup () {
    return setupStepProcessData()
  }
})
</script>
<style lang="scss" scoped>
::v-deep .etl-choose-column {
  max-height: 500px;
  overflow: auto;
}

.spinner {
  align-items: center;
  display: flex;
  height: 500px;
  justify-content: center;

  .svg-icon {
    height: 20px;
    margin-right: 0.5rem;
    width: 20px;
  }
}
</style>
