import { computed } from '@vue/composition-api'
import { defineFlowContext } from '@/modules/shared/flow/composable/flow'
import { useI18n } from '@/utils/composable/i18n'
import { createBasicImportFlowContext } from './basic'

import StepSelectFile from '../steps/stepSelectFile/StepSelectFile.vue'
import StepUploadFile from '@/modules/shared/flow/steps/stepUploadFiles/StepUploadFiles.vue'
import StepBasicSettings from '../steps/stepBasicSettings/StepBasicSettings.vue'
import StepConfigureDataSettings from '../steps/stepConfigureDataSettings/StepConfigureDataSettings.vue'

export const FLOW_ID = 'mini-app-import-flow'

/**
 * @typedef {object} ImportFlowContextOptions
 * @property {import('@vue/composition-api').SetupContext['emit']} emit
 */

export const {
  registerFlowContext: registerImportFlowContext,
  useFlowContext: useImportFlowContext
} = defineFlowContext({
  /** @param {ImportFlowContextOptions} */
  createFlowContext ({ emit }) {
    const { t } = useI18n()
    const context = createBasicImportFlowContext({
      emit,
      flowId: FLOW_ID,
      steps: [
        {
          key: 'SelectFile',
          title: () => t('editing.chooseFile'),
          component: StepSelectFile
        },
        {
          key: 'UploadFile',
          title: () => t('editing.uploadFile'),
          component: StepUploadFile
        },
        {
          key: 'BasicSettings',
          title: () => t('miniApp.settingsOfAppBasicInfo'),
          component: StepBasicSettings
        },
        {
          key: 'ConfigureDataSettings',
          title: () => t('miniApp.settingsOfAppDataSource'),
          component: StepConfigureDataSettings
        }
      ]
    })

    const { stepResultList } = context
    const fileToUploadList = computed(() => {
      return stepResultList.value[0]?.fileToUploadList ?? []
    })
    const uploadedFileList = computed(() => {
      return stepResultList.value[1]?.uploadedFileList ?? []
    })
    const uploadedImportData = computed(() => {
      /** @type {import('@/API/types/MiniApp').ImportUploadResultData} */
      const result = uploadedFileList.value[0]?.result ?? null
      return result
    })
    const basicSettings = computed(() => {
      return stepResultList.value[2]?.basicSettings ?? null
    })

    return {
      ...context,
      fileToUploadList,
      uploadedImportData,
      basicSettings
    }
  }
})

/** @type {(ctx: any) => ctx is ReturnType<typeof useImportFlowContext>} */
export const isMiniAppImportFlowContext = (ctx) => ctx?.flowId === FLOW_ID
