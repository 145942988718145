<template>
  <div>
    <single-file-status-row
      v-for="(file, index) in fileStatusList"
      :key="`file-item-${String(index)}`"
      v-bind="file"
      @delete="$emit('delete', index)"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import SingleFileStatusRow from './SingleFileStatusRow.vue'

export default defineComponent({
  name: 'FileStatusList',
  components: {
    SingleFileStatusRow
  },
  props: {
    /*
      type FileStatusList = {
        type: FileType;
        name: string;
        size: number;
        enable: boolean;
        msg?: string;
      }[]
    */
    fileStatusList: {
      type: Array,
      required: true
    }
  }
})
</script>
