<template>
  <confirm-dialog
    :title="$t('remoteDbConnection.sqlCreateTable')"
    class="edit-sql-dialog"
    @close="handleCancel"
  >
    <div
      slot="dialogBody"
      class="content"
    >
      <div class="table-name-block dialog-input-block">
        <label
          for="tableName"
          class="dialog-input-label"
        >{{ $t('remoteDbConnection.dataFrameName') }}</label>
        <input-verify
          v-validate="`required|max:${max}`"
          v-model.trim="formData.name"
          class="table-name-input"
          name="tableName"
        />
      </div>
      <div class="sql-content-block dialog-input-block">
        <label
          for="sqlContnet"
          class="dialog-input-label"
        >{{ $t('remoteDbConnection.sql') }}</label>
        <textarea
          v-validate="`required`"
          id="sqlContnet"
          v-model.trim="formData.sql"
          rows="5"
          label="SQL"
          class="sql-content-input"
          name="sqlContnet"
        />
        <div
          v-show="errors.has('sqlContnet')"
          class="error-text"
        >
          {{ errors.first('sqlContnet') }}
        </div>
      </div>
    </div>
    <template
      slot="dialogFooter"
      class="dialog-btn-block"
    >
      <button
        type="button"
        class="btn btn-outline"
        @click="handleCancel"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        :disabled="isProcessing"
        type="button"
        class="btn btn-default"
        @click="handleConfirm"
      >
        <span v-if="isProcessing">
          <svg-icon icon-class="spinner" />
          {{ $t('button.testConnecting') }}
        </span>
        <span v-else>{{ $t('button.confirm') }}</span>
      </button>
    </template>
  </confirm-dialog>
</template>
<script>
import ConfirmDialog from '@/components/dialog/ConfirmDialog'
import InputBlock from '@/components/InputBlock'
import InputVerify from '@/components/InputVerify'
import { Message } from 'element-ui'
import { testCreateTableSql } from '@/API/RemoteDbConnection'
import { defineComponent, ref } from '@vue/composition-api'
import { useMapGetters } from '@/utils/composable/vuex'
import { useValidatorWithErrors } from '@/utils/composable/validator'
import { useI18n } from '@/utils/composable/i18n'
import axios from 'axios'

export default defineComponent({
  name: 'EditSqlDialog',
  components: {
    ConfirmDialog,
    InputBlock,
    InputVerify
  },
  props: {
    connectionId: {
      type: Number,
      required: true
    },
    tableList: {
      type: Array,
      required: true
    },
    editingItem: {
      type: Object,
      default: null
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n()
    const { validator, errors } = useValidatorWithErrors()
    const formData = ref({
      name: props.editingItem?.name ?? '',
      sql: props.editingItem?.sql ?? ''
    })
    const isProcessing = ref(false)
    const { fieldCommonMaxLength: max } = useMapGetters('validation', ['fieldCommonMaxLength'])
    const cancelToken = ref(null)

    async function handleConfirm () {
      const result = await validator.validateAll()

      if (!result) return

      if (props.editingItem === null && (props.tableList.some(({ name }) => name === formData.value.name))) {
        Message({
          message: t('remoteDbConnection.tableNameDuplicate'),
          type: 'warning',
          duration: 3 * 1000,
          showClose: true
        })
        return
      }
      isProcessing.value = true
      // 測試 SQL
      try {
        cancelToken.value = axios.CancelToken.source()
        const response = await testCreateTableSql(props.connectionId, formData.value.sql, cancelToken.value.token)
        if (response?.message) {
          Message({
            message: response.message,
            type: 'warning',
            duration: 3 * 1000,
            showClose: true
          })
        }
        if (props.editingItem === null) {
          emit('add-sql-table', formData.value)
        } else {
          emit('update-editing-item', formData.value)
        }
      } catch {
      } finally {
        isProcessing.value = false
      }
    }
    async function handleCancel () {
      try {
        cancelToken.value?.cancel('cancel')
        cancelToken.value = null
      } catch (e) {
        console.log(e)
      }
      emit('cancel')
    }

    return {
      formData,
      isProcessing,
      max,
      errors,
      handleConfirm,
      handleCancel
    }
  }
})
</script>
<style lang="scss" scoped>
.edit-sql-dialog {
  &.confirm-dialog ::v-deep .dialog-container {
    top: 90px;
    width: 80%;
  }

  .dialog-input-block {
    text-align: left;
  }

  .sql-content-input {
    background-color: rgba(0, 0, 0, 0.55);
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    height: 40vh;
    padding: 8px;
    width: 100%;
  }

  .dialog-input-label {
    display: block;
    margin-bottom: 4px;

    .remark-info {
      color: #ffdf6f;
      font-size: 14px;
      margin-left: 4px;
    }
  }

  .dialog-btn-block {
    text-align: right;
  }
}
</style>
