import { useI18n } from '@/utils/composable/i18n'
import { createBasicSetupConnectionContext } from './basic'
import {
  createSftpConnection,
  getSftpConnectionList,
  updateSftpConnection,
  deleteSftpConnection,
  testSftpConnection,
  testExistedSftpConnection
} from '@/API/RemoteSftpConnection'
import SftpConnectionForm from '../components/SftpConnectionForm.vue'

/**
 * @returns {import('./basic').UseConnectionOperations}
 */
function useConnectionOperations () {
  return {
    testNewConnection: testSftpConnection,
    testExistedConnection: testExistedSftpConnection,
    createConnection: createSftpConnection,
    getConnections: getSftpConnectionList,
    updateConnection: updateSftpConnection,
    deleteConnection: deleteSftpConnection
  }
}

/**
 * @returns {import('./basic').UseConnectionForm}
 */
function useConnectionForm () {
  return {
    formComponent: SftpConnectionForm
  }
}

/**
 * @returns {import('./basic').UseConnectionInfoFormatter}
 */
function useConnectionInfoFormatter () {
  const { t } = useI18n()
  return {
    formatInfo: (connection) => {
      return {
        title: connection.name,
        entries: [
            `Host: ${connection.host}`,
            `Port: ${connection.port}`,
            `${t('editing.userTitle')}: ${connection.username}`
        ]
      }
    }
  }
}

/**
 * @param {{ refs: import('@vue/composition-api').SetupContext['refs'] }}
 */
export function createSftpSetupConnectionContext ({ refs }) {
  return createBasicSetupConnectionContext({
    refs,
    useConnectionOperations,
    useConnectionForm,
    useConnectionInfoFormatter
  })
}
