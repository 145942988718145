<template>
  <div>
    <step-card-block
      v-for="(file, index) in selectedFiles"
      :key="`selected-file-${String(index)}`"
      type="bright"
      class="file-info"
    >
      <svg-icon
        :icon-class="{
          [FileType.CSV]: 'file-csv',
          [FileType.EXCEL]: 'file-xlsx'
        }[file.type]"
      />
      <div class="file-info__content">
        <div class="file-info__filename">
          {{ file.name }}
        </div>
        <div class="file-info__path">
          {{ formatDisplayPath(file.path) }}
        </div>
      </div>
      <div
        class="file-info__remove"
        @click="$emit('remove-selected-file', index)"
      >
        <svg-icon icon-class="close" />
      </div>
    </step-card-block>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import { FileType } from '../../../constants'

export default defineComponent({
  name: 'SelectedFileList',
  components: {
    StepCardBlock
  },
  props: {
    selectedFiles: {
      type: Array,
      required: true
    }
  },
  setup () {
    function formatDisplayPath (path) {
      const splitted = path.split('/').filter(Boolean)
      if (splitted.length <= 2) return path
      return `.../${splitted.slice(splitted.length - 2).join('/')}`
    }

    return {
      FileType,
      formatDisplayPath
    }
  }
})
</script>

<style lang="scss" scoped>
.file-info {
  display: flex;
  padding: 1rem;

  .svg-icon {
    height: 21px;
    margin-right: 0.5rem;
    width: 21px;
  }

  &__content {
    font-size: 14px;
  }

  &__filename {
    font-weight: 600;
  }

  &__path {
    color: #ccc;
  }

  &__remove {
    align-items: center;
    color: #a7a7a7;
    cursor: pointer;
    display: flex;
    margin-left: auto;

    .svg-icon {
      height: 1rem;
      width: 1rem;
    }
  }
}
</style>
