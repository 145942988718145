import { computed } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import { createBasicImportFlowContext } from './basic'

import StepSetupConnection from '../steps/stepSetupConnection/StepSetupConnection.vue'
import StepSftpSelectFiles from '../steps/stepSftpSelectFiles/StepSftpSelectFiles.vue'
import StepUploadFiles from '@/modules/shared/flow/steps/stepUploadFiles/StepUploadFiles.vue'
import StepConfirmSheets from '../steps/stepConfirmSheets/StepConfirmSheets.vue'
import StepProcessData from '../steps/stepProcessData/StepProcessData.vue'

export const FLOW_ID = 'sftp-flow'

/** @param {{ emit: import('@vue/composition-api').SetupContext['emit'] }} */
export function createSftpFlowContext ({ emit }) {
  const { t } = useI18n()
  const basicImportFlowContext = createBasicImportFlowContext({
    emit,
    flowId: FLOW_ID,
    steps: [
      {
        key: 'SetupConnection',
        title: () => t('editing.connectSftp'),
        component: StepSetupConnection
      },
      {
        key: 'SelectFiles',
        title: () => t('editing.chooseFile'),
        component: StepSftpSelectFiles
      },
      {
        key: 'UploadFiles',
        title: () => t('editing.transferData'),
        component: StepUploadFiles
      },
      {
        key: 'ConfirmSheets',
        title: () => t('editing.chooseDataSheet'),
        component: StepConfirmSheets
      },
      {
        key: 'ProcessData',
        title: () => t('editing.dataProcessing'),
        component: StepProcessData
      }
    ]
  })

  const { stepResultList } = basicImportFlowContext
  const sftpConnectionId = computed(() => {
    return stepResultList.value[0]?.connectionId ?? null
  })
  const fileToUploadList = computed(() => {
    return stepResultList.value[1]?.fileToUploadList ?? []
  })
  const uploadedFileList = computed(() => {
    return stepResultList.value[2]?.uploadedFileList ?? []
  })
  const analyzedResultList = computed(() => {
    return stepResultList.value[3]?.analyzedResultList ?? []
  })

  return {
    ...basicImportFlowContext,
    sftpConnectionId,
    fileToUploadList,
    uploadedFileList,
    analyzedResultList
  }
}
