<template>
  <div class="input-setting-dialog">
    <div class="dialog-body">
      <div class="setting-block model-name-container">
        <input-block
          v-validate="`required|max:${max}`"
          :label="$t('model.upload.setModelName')"
          v-model="modelName"
          class="model-name-input"
          name="modelName"
        />
      </div>
    </div>
    <div class="dialog-footer">
      <div class="dialog-button-block">
        <button
          class="btn btn-outline"
          @click="handleExit"
        >
          {{ $t('button.cancel') }}
        </button>
        <button
          class="btn btn-default"
          @click="handleNextStep"
        >
          {{ $t('button.nextStep') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { setupStepEditNameFiles } from './composable'

import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import InputBlock from '@/components/InputBlock.vue'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    InputBlock
  },
  setup () {
    return setupStepEditNameFiles()
  }
})
</script>
<style lang="scss" scoped>
.input-setting-dialog {
  .setting-block {
    background: rgba(50, 58, 58, 0.95);
    border-radius: 5px;
    padding: 24px;

    &.model-name-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40vh;
    }

    .model-name-input {
      width: 35%;
    }
  }
}
</style>
