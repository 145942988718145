<template>
  <div class="dialog-footer">
    <div class="dialog-button-block">
      <slot />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'StepFooterButtonListBlock'
})
</script>
