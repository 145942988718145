import { ref, computed } from '@vue/composition-api'
import { useImportFlowContext } from '../../../composable'
import { buildAppByImport } from '@/API/MiniApp'
import { useMiniAppModuleStore } from '@/modules/miniApp'
import { Message } from 'element-ui'
import { useI18n } from '@/utils/composable/i18n'

export function setupStep () {
  const {
    handleDone,
    handleExit,
    handlePrevStep,
    groupId,
    uploadedImportData,
    basicSettings
  } = useImportFlowContext()

  const statusOfFinish = ref([
    false,
    uploadedImportData.value.dataSettings.modelList.length === 0
  ])
  const isFinished = computed(() => statusOfFinish.value.every(Boolean))

  const dataFrameListRef = ref()
  const modelListRef = ref()

  function getUpdateData () {
    return {
      dataSettings: {
        dataFrameList: dataFrameListRef.value.getUpdateData() ?? [],
        modelList: modelListRef.value?.getUpdateData() ?? []
      }
    }
  }

  const { t } = useI18n()
  const { addPendingAppInfoToList } = useMiniAppModuleStore()
  const isPending = ref(false)
  async function handleClickBuild () {
    try {
      isPending.value = true
      const { newAppId } = await buildAppByImport(uploadedImportData.value.importTaskId, {
        ...getUpdateData(),
        ...basicSettings.value,
        groupId: groupId.value
      })
      addPendingAppInfoToList({
        id: newAppId,
        type: 'import'
      })
      Message({
        message: t('miniApp.notificationMessage.startImport', { appName: basicSettings.value.name }),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })

      handleDone()
      handleExit()
    } catch (error) {
      console.error(error)
    } finally {
      isPending.value = false
    }
  }

  return {
    handleExit,
    handlePrevStep,
    handleClickBuild,
    isPending,
    groupId,
    uploadedImportData,
    statusOfFinish,
    isFinished,
    dataFrameListRef,
    modelListRef
  }
}
