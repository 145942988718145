import { ref, computed, onMounted } from '@vue/composition-api'
import { dataPreprocessor } from '@/API/DataSource'
import { Message } from 'element-ui'
import { useMapState, useMapMutations } from '@/utils/composable/vuex'
import { useI18n } from '@/utils/composable/i18n'
import { useImportFlowContext } from '../../../composable'

export function setupStepProcessData () {
  const {
    handlePrevStep,
    analyzedResultList,
    handleExit,
    handleDone
  } = useImportFlowContext()
  const isLoading = ref(false)
  const {
    currentTableIndex,
    etlTableList
  } = useMapState('dataManagement', ['currentTableIndex', 'etlTableList'])
  const hasInited = ref(false)
  const canMountEtlComponent = computed(() => hasInited.value && (etlTableList.value[currentTableIndex.value] ?? null) !== null)
  const hasAtLeastOneColumnPerTable = computed(() =>
    etlTableList.value.every((etlTable) => {
      return etlTable.columns.some(column => column.active)
    })
  )

  const {
    updateEtlTableList,
    clearEtlTableList,
    changeCurrentTableIndex
  } = useMapMutations('dataManagement', ['updateEtlTableList', 'clearEtlTableList', 'changeCurrentTableIndex'])
  const { setProcessingDataFrameList } = useMapMutations('dataSource', ['setProcessingDataFrameList'])
  function initEtlData () {
    changeCurrentTableIndex(0)
    clearEtlTableList()
    JSON.parse(JSON.stringify(analyzedResultList.value)).forEach(updateEtlTableList)
    hasInited.value = true
  }

  const { t } = useI18n()
  async function handleClickBuildData () {
    if (!hasAtLeastOneColumnPerTable.value) {
      Message({
        message: t('etl.pleaseSelectAtLeastOneColumnPerTable'),
        type: 'warning',
        duration: 3 * 1000,
        showClose: true
      })
      return
    }
    try {
      isLoading.value = true
      await Promise.all(etlTableList.value.map(async (etlTable) => {
        await dataPreprocessor(etlTable)
        setProcessingDataFrameList(etlTable)
      }))
      handleDone()
      handleExit()
    } catch (error) {
      console.log(error)
    } finally {
      isLoading.value = false
    }
  }

  onMounted(() => {
    initEtlData()
  })

  return {
    isLoading,
    canMountEtlComponent,
    hasAtLeastOneColumnPerTable,
    handleExit,
    handlePrevStep,
    handleClickBuildData
  }
}
