import { useCurrentFlowContext } from '../../../composable/flow'
import { isLocalFileFlowContext, isSftpFlowContext } from '@/modules/dataManagement/importFlow/composable'
import { createLocalFileUploadFilesContext } from '@/modules/dataManagement/importFlow/steps/stepUploadFiles/composable/localFile'
import { createSftpUploadFilesContext } from '@/modules/dataManagement/importFlow/steps/stepUploadFiles/composable/sftp'
import { createMiniAppImportUploadFileContext } from '@/modules/miniApp/importFlow/steps/stepUploadFile/composable'
import { isMiniAppImportFlowContext } from '@/modules/miniApp/importFlow/composable'
import { isModelUploadFilesContext } from '@/modules/modelManagement/importFlow/composable'
import { createModelUploadFilesContext } from '@/modules/modelManagement/importFlow/steps/stepUploadFiles/composable/model'
// import { createModelUploadFilesContext } from '@/modules/modelManagement/importFlow/steps/stepUploadFiles/composable/flow'

export function setupStepUploadFiles () {
  const flowContext = useCurrentFlowContext()
  if (isLocalFileFlowContext(flowContext)) {
    return createLocalFileUploadFilesContext()
  } else if (isSftpFlowContext(flowContext)) {
    return createSftpUploadFilesContext()
  } else if (isMiniAppImportFlowContext(flowContext)) {
    return createMiniAppImportUploadFileContext()
  } else if (isModelUploadFilesContext(flowContext)) {
    return createModelUploadFilesContext
  } else {
    throw new Error('"Step: UploadFiles" is not allowed in current flow')
  }
}
