<template>
  <div class="step-status-block">
    <div class="step-status-block__title dialog-title">
      {{ currentStepTitle }}
    </div>
    <div class="step-status-block__steps">
      <div
        v-for="(title, index) in stepTitleList"
        :key="index"
        class="step-status-block__step"
        :class="{
          'step-status-block__step--active': currentStepIndex > index
        }"
      >
        <div
          class="step"
          :class="{
            'step--current': currentStepIndex === index,
            'step--active': currentStepIndex > index
          }"
        >
          <div class="step__badge">
            <template v-if="currentStepIndex <= index">
              {{ index + 1 }}
            </template>
            <template v-else>
              <svg-icon icon-class="checked" />
            </template>
          </div>
          <div class="step__text">
            {{ title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'StepStatusBlock',
  props: {
    currentStepTitle: {
      type: String,
      required: true
    },
    stepTitleList: {
      type: Array,
      required: true
    },
    currentStepIndex: {
      type: Number,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.step-status-block {
  &__title {
    font-weight: 600;
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;
    width: 100%;
  }

  &__step {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      flex: auto;

      &::after {
        border-bottom: 1px solid #a7a7a7;
        content: '';
        display: block;
        flex: 1;
        margin: 0 10px;
      }
    }

    &--active {
      &:not(:last-child) {
        &::after {
          border-color: #fff;
        }
      }
    }
  }
}

.step {
  text-align: center;

  &__badge {
    border: 1px solid  #bdbdbd;
    border-radius: 50%;
    color: #bdbdbd;
    flex: initial;
    height: 32px;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 8px;
    text-align: center;
    width: 32px;
  }

  &__text {
    color: #979797;
    flex: initial;
    letter-spacing: 1px;
  }

  &--current &__badge {
    background-color: $theme-color-primary;
    border-color: $theme-color-primary;
    color: #000;
  }

  &--current &__text {
    color: $theme-color-primary;
  }

  &--active &__badge {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
  }

  &--active &__text {
    color: #fff;
  }
}
</style>
