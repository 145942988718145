<template lang="">
  <div>
    <div
      v-if="successResultList.length > 0"
      class="result-block"
    >
      <div class="block-title">
        {{ $t('editing.readyToBuild') }}
      </div>
      <step-card-block>
        <file-list
          :file-result-list="successResultList"
          @update-selected-list="handleUpdateSelectedList"
        />
      </step-card-block>
    </div>
    <div
      v-if="failResultList.length > 0"
      class="result-block"
    >
      <div class="block-title">
        {{ $t('editing.unableToBuild') }}
      </div>
      <step-card-block>
        <file-list :file-result-list="failResultList" />
      </step-card-block>
    </div>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
        :disabled="isLoading"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        class="btn btn-outline"
        @click="handleClickPrevStep"
        :disabled="isLoading"
      >
        {{ $t('button.prevStep') }}
      </button>
      <template v-if="selectedResult.length > 0">
        <button
          class="btn btn-default"
          @click="handleClickNextStep"
          :disabled="isLoading"
        >
          {{ $t('button.nextStep') }}
        </button>
      </template>
    </step-footer-button-list-block>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { setupStepConfirmSheets } from './composable'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import FileList from './components/FileResultList.vue'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    FileList
  },
  setup () {
    return setupStepConfirmSheets()
  }
})
</script>
<style lang="scss" scoped>
.result-block {
  margin-bottom: 2rem;

  .block-title {
    margin-bottom: 1rem;
  }
}
</style>
