import request from '@/utils/publicRequest'

/**
 * @typedef {object} ConnectionInfo
 * @property {string} name
 * @property {string} host
 * @property {number} port
 * @property {string} username
 * @property {string} password
 * @property {number} groupId
 */

const BASE_URL = '/sftpConnections'

/**
 * 建立 Sftp 連線
 * @param {ConnectionInfo} connectionInfo 連線資訊
 */
export function createSftpConnection (connectionInfo) {
  return request({
    url: BASE_URL,
    method: 'POST',
    data: connectionInfo
  })
}

/**
 * 依照連線資訊 ID 刪除連線資訊
 * @param {number} sftpConnectionId 連線 id
 */
export function deleteSftpConnection (sftpConnectionId) {
  return request({
    url: `${BASE_URL}/${sftpConnectionId}`,
    method: 'DELETE'
  })
}

/**
 * 依照連線資訊 ID 更新連線資訊
 * @param {number} sftpConnectionId 連線 id
 * @param {ConnectionInfo} connectionInfo 更改資訊
 */
export function updateSftpConnection (sftpConnectionId, connectionInfo) {
  return request({
    url: `${BASE_URL}/${sftpConnectionId}`,
    method: 'PUT',
    data: connectionInfo
  })
}

/**
 * 依照 groupId 取得歷史 sftp 連線紀錄清單
 * @param {number} groupId
 */
export function getSftpConnectionList (groupId) {
  return request({
    url: BASE_URL,
    method: 'GET',
    params: {
      groupId
    }
  })
}

/**
 * 測試新 sftp 連線
 * @param {ConnectionInfo} connectionInfo
 */
export function testSftpConnection (connectionInfo) {
  return request({
    url: `${BASE_URL}/test`,
    method: 'POST',
    data: connectionInfo
  })
}

/**
 * 測試已新增的連線
 * @param {number} sftpConnectionId
 */
export function testExistedSftpConnection (sftpConnectionId, cancelFunction = null) {
  return request({
    url: `${BASE_URL}/${sftpConnectionId}/test`,
    method: 'POST',
    cancelToken: cancelFunction
  })
}

/**
 * 依照 path 取得 sftp 連線下目錄內資料
 * @param {number} sftpConnectionId
 * @param {string} [path]
 */
export function getFolderDataByPath (sftpConnectionId, path) {
  return request({
    url: `${BASE_URL}/${sftpConnectionId}/data`,
    method: 'GET',
    params: {
      path
    }
  })
}

/**
 * 依照 path 讓後端用 sftp 去拉檔案
 * @param {number} sftpConnectionId
 * @param {string} path
 */
export function uploadFile (sftpConnectionId, path, cancelFunction = null) {
  return request({
    url: `${BASE_URL}/${sftpConnectionId}/upload-parse`,
    method: 'POST',
    data: {
      sftpFilePath: path
    },
    cancelToken: cancelFunction
  })
}

/**
 * @typedef {object} FileInfo
 * @property {string} sftpPath
 * @property {number} fileId
 * @property {string} fileName
 * @property {string} fileType
 * @property {object} tabDetail
 */

/**
 * sftp 分析檔案
 * @param {number} sftpConnectionId
 * @param {number} fileId
 * @param {FileInfo} fileInfo
 */
export function analyzeFile (sftpConnectionId, fileId, fileInfo) {
  return request({
    url: `${BASE_URL}/${sftpConnectionId}/files/${fileId}/analyze`,
    method: 'POST',
    data: fileInfo
  })
}
