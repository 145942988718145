<template>
  <div>
    <step-card-block>
      <div class="setup-connection">
        <div class="mode">
          <div
            v-if="mode !== Mode.EDIT_CONNECTION"
            class="mode__header"
          >
            <h3 class="mode__title">
              {{ currentModeTitle }}
            </h3>
            <button
              class="mode__switch"
              @click="handleSwitchMode"
              :disabled="isLoading"
            >
              {{ switchModeButtonText }}
            </button>
          </div>
          <component
            ref="modeComponent"
            :is="currentModeComponent"
          />
        </div>
      </div>
    </step-card-block>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        :disabled="isLoading"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        v-if="mode === Mode.CREATE_CONNECTION"
        class="btn btn-default btn-connect"
        :disabled="isLoading"
        @click="handleConnect(null)"
      >
        <template v-if="isLoading">
          <svg-icon icon-class="spinner" />
        </template>
        <template v-else>
          {{ $t('button.connect') }}
        </template>
      </button>
      <template v-else-if="mode === Mode.EDIT_CONNECTION">
        <button
          class="btn btn-default btn-connect"
          :disabled="isLoading"
          @click="handleEndEditConnection(true)"
        >
          <template v-if="isLoading">
            <svg-icon icon-class="spinner" />
          </template>
          <template v-else>
            {{ $t('button.saveAndConnect') }}
          </template>
        </button>
        <button
          class="btn btn-default btn-connect"
          :disabled="isLoading"
          @click="handleEndEditConnection(false)"
        >
          <template v-if="isLoading">
            <svg-icon icon-class="spinner" />
          </template>
          <template v-else>
            {{ $t('button.save') }}
          </template>
        </button>
      </template>
    </step-footer-button-list-block>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { setupStepSetupConnection } from './composable'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock
  },
  setup (props, ctx) {
    return setupStepSetupConnection(ctx)
  }
})
</script>

<style lang="scss" scoped>
.setup-connection {
  .mode {
    margin: auto;
    max-width: 60%;

    &__header {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      margin-right: 1rem;
    }

    &__switch {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 5px;
      color: #fff;
      outline: none;
      padding: 8px 16px;
    }
  }
}

.btn.btn-default.btn-connect:disabled {
  font-size: 1.5rem;
  opacity: 0.8;
}
</style>
