<template>
  <div>
    <step-card-block class="file-select">
      <sftp-file-explorer
        v-model="selectedFiles"
        :sftp-connection-id="sftpConnectionId"
      />
      <selected-result-block
        :selected-files="selectedFiles"
        @remove-selected-file="selectedFiles.splice($event, 1)"
      />
    </step-card-block>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        class="btn btn-outline"
        @click="handlePrevStep"
      >
        {{ $t('button.prevStep') }}
      </button>
      <button
        v-if="selectedFiles.length > 0"
        class="btn btn-default"
        @click="handleClickNextStep"
      >
        {{ $t('button.nextStep') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { setupStepSftpSelectFiles } from './composable'

import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import SftpFileExplorer from './components/SftpFileExplorer.vue'
import SelectedResultBlock from './components/SelectedResultBlock.vue'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    SftpFileExplorer,
    SelectedResultBlock
  },
  setup () {
    return setupStepSftpSelectFiles()
  }
})
</script>
<style lang="scss" scoped>
.file-select {
  display: flex;
  height: 500px;
}
</style>
