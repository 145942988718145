<template>
  <div>
    <step-card-block>
      <div class="form-container">
        <app-settings-form ref="form" />
      </div>
    </step-card-block>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        class="btn btn-outline"
        @click="handlePrevStep"
      >
        {{ $t('button.prevStep') }}
      </button>
      <button
        class="btn btn-default"
        @click="handleNextStep"
      >
        {{ $t('button.nextStep') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { setupStep } from './composable'

import AppSettingsForm from '@/pages/miniApp/miniAppList/components/AppSettingsForm.vue'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'

export default defineComponent({
  name: 'Step',
  components: {
    AppSettingsForm,
    StepCardBlock,
    StepFooterButtonListBlock
  },
  setup () {
    return setupStep()
  }
})
</script>

<style lang="scss" scoped>
.form-container {
  margin: 2rem auto;
  max-width: 475px;
}
</style>
