<template>
  <div>
    <input
      style="display: none;"
      ref="fileUploadInput"
      :accept="acceptFileTypes"
      type="file"
      name="fileUploadInput"
      @change="handleSelectFile($event.target.files)"
    >
    <template v-if="selectedFiles.length === 0">
      <step-card-block class="file-select">
        <upload-block
          :bottom-message="$t('editing.clickToSelectFiles')"
          :drag-enter="isDragEnter"
          class="empty-upload-block"
          @create="startSelectFile"
          @drop.native.prevent="dropFiles($event)"
          @dragover.native.prevent
          @dragenter.native="isDragEnter = true"
          @dragleave.native="isDragEnter = false"
        >
          <div
            slot="uploadLimit"
            class="empty-upload-block__remark"
          >
            <div class="remark__title">
              【{{ $t('editing.uploadLimitTitle') }}】
            </div>
            <div>
              1. {{ $t('miniApp.form.acceptFileType') }}
            </div>
            <div>
              2. {{ $t('editing.uploadLimitCount', { countLimit: fileCountLimit }) }}
            </div>
          </div>
        </upload-block>
      </step-card-block>
    </template>
    <template v-else>
      <div
        :class="{ droppable: isDragEnter }"
        @dragover.prevent
        @dragenter="isDragEnter = true"
        @dragleave="isDragEnter = false"
        @drop.prevent="dropFiles($event)"
      >
        <div
          class="result-block"
        >
          <div class="block-title">
            {{ $t('editing.canUpload') }}
          </div>
          <step-card-block class="list-container">
            <file-status
              v-bind="selectedFiles[0]"
              @delete="handleDeleteSelectedFile(0)"
            />
          </step-card-block>
        </div>
      </div>
      <div class="file-chosen-info">
        <button
          class="btn-m btn-secondary btn-has-icon"
          @click="startSelectFile"
        >
          <svg-icon
            icon-class="file-plus"
            class="icon"
          />
          {{ $t('fileDataUpdate.reChoose') }}
        </button>
      </div>
    </template>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        v-if="selectedFiles.length > 0"
        class="btn btn-default"
        @click="handleClickNextStep"
      >
        {{ $t('button.confirmUpload') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { setupStep } from './composable'

import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import UploadBlock from '@/components/UploadBlock.vue'
import FileStatus from './components/FileStatus.vue'

export default defineComponent({
  name: 'StepSelectFile',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    UploadBlock,
    FileStatus
  },
  setup (props, ctx) {
    return setupStep(ctx)
  }
})
</script>
<style lang="scss" scoped>
.file-select {
  overflow: hidden;
  padding: 0;
}

.empty-upload-block {
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;

  &__remark {
    color: $theme-color-warning;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;

    .remark {
      &__title {
        margin-bottom: 4px;
      }
    }
  }
}

.result-block {
  margin-bottom: 1rem;

  .block-title {
    margin-bottom: 1rem;
  }
}

.file-chosen-info {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin-bottom: 1rem;

  .file-chosen-remark {
    margin-right: 16px;

    &.is-warning {
      color: $theme-color-danger;
    }
  }
}

.droppable .list-container {
  background-color: #485454;
}

</style>
