import { ref } from '@vue/composition-api'
import { useImportFlowContext } from '../../../composable'

export function setupStep () {
  const {
    handleExit,
    handleJumpStep,
    handleNextStep,
    handleSaveResult
  } = useImportFlowContext()

  const form = ref()

  async function handleClickNextStep () {
    const formData = await form.value.getFormData()
    if (formData === null) {
      return
    }
    handleSaveResult({
      basicSettings: formData
    })
    handleNextStep()
  }

  return {
    form,
    handleExit,
    handlePrevStep () {
      handleJumpStep('SelectFile')
    },
    handleNextStep: handleClickNextStep
  }
}
