import axios from 'axios'
import { uploadFile } from '@/API/MiniApp'
import { createBasicUploadFilesContext } from '@/modules/shared/flow/steps/stepUploadFiles/composable/basic'

export function createMiniAppImportUploadFileContext () {
  return createBasicUploadFilesContext({
    useUploadFileOperations () {
      return {
        uploadFile: (file) => uploadFile(
          file.info.data.formData,
          (percentage) => {
            file.progress = percentage
          },
          new axios.CancelToken((c) => {
            file.cancelUpload = c
          }))
      }
    }
  })
}
