<template>
  <form class="db-connection-form">
    <!-- Avoid autofill -->
    <input
      class="hidden-input"
      name="db-username"
      type="text"
    >
    <input
      class="hidden-input"
      name="db-password"
      type="password"
    >
    <!-- ^^^Avoid autofill^^^ -->

    <input-block
      v-validate="`required|max:${max}`"
      v-model="formData.name"
      name="connection-name"
      :label="$t('editing.connectionName')"
      :disabled="isLoading"
    />
    <input-block
      v-validate="'required'"
      v-model="formData.account"
      name="db-username"
      :label="$t('editing.loginAccount')"
      :disabled="isLoading"
    />
    <input-block
      v-validate="'required'"
      v-model="formData.password"
      name="db-password"
      type="password"
      :label="$t('editing.loginPassword')"
      :disabled="isLoading"
    />
    <div class="database-type-select">
      <label
        for="dbType"
        class="database-type-select__label"
      >{{ $t('editing.databaseType') }}</label>
      <default-select
        v-validate="'required'"
        v-model="formData.databaseType"
        :option-list="dbOptionList"
        :placeholder="$t('editing.defaultOption')"
        name="dbType"
        :disabled="isLoading"
      />
    </div>
    <input-block
      v-validate="'required'"
      v-model="formData.database"
      name="db-name"
      :label="formData.databaseType === 'ORACLE' ? 'SID' : 'Database'"
      :disabled="isLoading"
    />
    <input-block
      v-model="formData.schema"
      name="db-schema"
      label="Schema"
      :disabled="isLoading"
    />
    <div class="origin-input-block">
      <input-block
        v-validate="'required'"
        v-model="formData.host"
        name="db-host"
        label="Host"
        :disabled="isLoading"
      />
      <input-block
        v-validate="'positiveInt'"
        v-model="formData.port"
        name="db-port"
        label="Port"
        :disabled="isLoading"
      />
    </div>
    <input-block
      v-validate="'max:128'"
      v-if="formData.databaseType === 'MSSQL'"
      v-model="formData.instanceName"
      class="dialog-input"
      label="Instance"
      name="instanceName"
    />
  </form>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useValidator } from '@/utils/composable/validator'
import { useMapGetters } from '@/utils/composable/vuex'
import InputBlock from '@/components/InputBlock.vue'
import DefaultSelect from '@/components/select/DefaultSelect'

export default defineComponent({
  name: 'DbConnectionForm',
  components: {
    InputBlock,
    DefaultSelect
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const validator = useValidator()
    const initFormData = JSON.parse(
      JSON.stringify(props.initData?.connectionData ?? null)
    )
    if (initFormData) {
      initFormData.port = String(initFormData.port)
    }
    const formData = ref(initFormData ?? {
      name: '',
      account: '',
      password: '',
      databaseType: '',
      database: '',
      schema: '',
      host: '',
      port: '',
      instanceName: ''
    })

    const dbOptionList = ref([
      {
        name: 'MSSQL',
        value: 'MSSQL'
      },
      {
        name: 'POSTGRESQL',
        value: 'POSTGRESQL'
      },
      {
        name: 'ORACLE',
        value: 'ORACLE'
      },
      {
        name: 'MYSQL',
        value: 'MYSQL'
      }
    ])

    async function getFormData () {
      const valid = await validator.validateAll()
      if (!valid) return null
      return {
        ...formData.value,
        port: +formData.value.port
      }
    }

    const { fieldCommonMaxLength: max } = useMapGetters('validation', ['fieldCommonMaxLength'])

    return {
      dbOptionList,
      formData,
      getFormData,
      max
    }
  }
})
</script>

<style lang="scss" scoped>
.hidden-input {
  border: 0;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0;
}

::v-deep label {
  opacity: 0.5;
}

.input-block {
  margin-bottom: 3.5rem;
}

.database-type-select {
  margin-bottom: 1.75rem;
  margin-top: -2rem;

  &__label {
    font-size: 14px;
  }
}

.origin-input-block {
  align-items: center;
  display: flex;
  margin-bottom: 3rem;

  .input-block {
    margin: 0;

    &:nth-of-type(1) {
      flex: 1;
      margin-right: 1rem;
    }

    &:nth-of-type(2) {
      flex-basis: 80px;
    }
  }
}
</style>
