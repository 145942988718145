<template>
  <div>
    <single-uploading-file-row
      v-for="(file, index) in uploadingFileList"
      :key="`file-item-${String(index)}`"
      v-bind="file"
      @cancel-upload="$emit('cancel-upload', index)"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import SingleUploadingFileRow from './SingleUploadingFileRow.vue'

export default defineComponent({
  name: 'UploadingFileList',
  components: {
    SingleUploadingFileRow
  },
  props: {
    /*
      type UploadingFileList = {
        type: FileType;
        name: string;
        size: number;
        status: UploadStatus;
        progress?: 'indeterminate' | number;
      }[]
    */
    uploadingFileList: {
      type: Array,
      required: true
    }
  }
})
</script>
