import { computed } from '@vue/composition-api'
import { useModelUploadFilesContext } from '../../../composable'
import { useMapState, useMapMutations, useMapGetters } from '@/utils/composable/vuex'

export function setupStepEditNameFiles () {
  const {
    handleNextStep,
    handleExit
  } = useModelUploadFilesContext()

  const { currentUploadModelName } = useMapState('modelManagement', ['currentUploadModelName'])
  const { fieldCommonMaxLength } = useMapGetters('validation', ['fieldCommonMaxLength'])
  const { updateModelName } = useMapMutations('modelManagement', ['updateModelName', 'updateShowCreateModelDialog'])

  const modelName = computed({
    get: () => currentUploadModelName.value,
    set: (value) => { updateModelName(value) }
  })
  const max = computed(() => fieldCommonMaxLength)
  return {
    max,
    modelName,
    handleNextStep,
    handleExit
  }
}
