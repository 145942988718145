import { toEnum } from '@/utils/general'

export const ImportType = toEnum({
  LOCAL: 'LOCAL',
  REMOTE_DB: 'REMOTE_DB',
  REMOTE_SFTP: 'REMOTE_SFTP'
})

export const ImportTypePermissionMap = {
  LOCAL: 'import_file',
  REMOTE_DB: 'import_database',
  REMOTE_SFTP: 'import_database'
}

export const ConnectionSetupMode = toEnum({
  CREATE_CONNECTION: 0,
  SELECT_CONNECTION: 1,
  EDIT_CONNECTION: 2
})

export const FileType = toEnum({
  CSV: 'CSV',
  EXCEL: 'EXCEL'
})

export const FileTypeMap = {
  csv: FileType.CSV,
  xls: FileType.EXCEL,
  xlsx: FileType.EXCEL,
  CSV: FileType.CSV,
  XLS: FileType.EXCEL,
  XLSX: FileType.EXCEL,
  'text/csv': FileType.CSV,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileType.EXCEL,
  'application/vnd.ms-excel': FileType.EXCEL
}

export const TableType = toEnum({
  DB: 'DB',
  SQL: 'SQL'
})
