import { isLocalFileFlowContext, isSftpFlowContext, useImportFlowContext } from '../../../composable'
import { createLocalFileConfirmSheetsContext } from './localFile'
import { createSftpConfirmSheetsContext } from './sftp'

export function setupStepConfirmSheets () {
  const flowContext = useImportFlowContext()
  if (isLocalFileFlowContext(flowContext)) {
    return createLocalFileConfirmSheetsContext()
  } else if (isSftpFlowContext(flowContext)) {
    return createSftpConfirmSheetsContext()
  } else {
    throw new Error('"Step: ConfirmSheets" is not allowed in current flow')
  }
}
