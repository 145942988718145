<template>
  <div class="result">
    <div class="result__title">
      {{ $t('editing.currentLoadedDataFrame') }}
    </div>
    <step-card-block>
      <template v-if="isLoading">
        <spinner size="50" />
      </template>
      <template v-else>
        <div class="action-header">
          <button
            type="button"
            class="btn-m btn-default btn-open-edit-dialog"
            @click="isEditingSql = true"
          >
            {{ $t('button.addSqlScript') }}
          </button>
          <input-block
            v-model="queryString"
            :label="$t('etl.tableSearch')"
          />
        </div>
        <div
          v-if="filteredTableList.length > 0"
          class="result__block"
        >
          <table-list
            v-model="selectedTableNameList"
            :table-list="filteredTableList"
            :disabled="isProcessing"
            @start-edit="editingTableName = $event"
          />
        </div>
        <empty-info-block
          v-else
          :msg="$t('message.emptyResult')"
        />
      </template>

      <div class="warning-text">
        {{ $t('etl.tableChosenLimit', { number: tableConnectionLimit }) }}
      </div>
    </step-card-block>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
        :disabled="isProcessing"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        class="btn btn-outline"
        @click="handlePrevStep"
        :disabled="isProcessing"
      >
        {{ $t('button.prevStep') }}
      </button>
      <template v-if="selectedTableList.length > 0">
        <button
          class="btn btn-default"
          @click="handleClickNextStep"
          :disabled="isProcessing"
        >
          {{ $t('button.nextStep') }}
        </button>
      </template>
    </step-footer-button-list-block>

    <edit-sql-dialog
      v-if="isEditingSql"
      :connection-id="dbConnectionId"
      :table-list="tableList"
      :editing-item="editingItem"
      @cancel="isEditingSql = false"
      @add-sql-table="handleAddSqlTable"
      @update-editing-item="handleUpdateEditingItem"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { setupStepDbSelectTable } from './composable'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import TableList from './components/TableList.vue'
import EditSqlDialog from './components/EditSqlDialog.vue'
import EmptyInfoBlock from '@/components/EmptyInfoBlock.vue'
import InputBlock from '@/components/InputBlock.vue'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    EmptyInfoBlock,
    InputBlock,
    TableList,
    EditSqlDialog
  },
  setup () {
    return setupStepDbSelectTable()
  }
})
</script>
<style lang="scss" scoped>

.action-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.result {
  &__title,
  &__block {
    margin-bottom: 1rem;
  }
}

.empty-info-block {
  margin-bottom: 1rem;
}

.warning-text {
  color: #ffdf6f;
  font-size: 14px;
  text-align: end;
}
</style>
