<template>
  <div
    class="table-list"
    :class="{
      'table-list--disabled': disabled
    }"
  >
    <div
      v-for="table in tableList"
      :key="table.name"
      class="table-list__item"
    >
      <label class="checkbox">
        <step-card-block
          class="table-list__item-container"
          type="bright"
        >
          <div class="checkbox-label">
            <input
              v-model="selectedTableList"
              :value="table.name"
              type="checkbox"
              :disabled="disabled"
            >
            <div class="checkbox-square" />
          </div>
          <template v-if="table.type === TableType.SQL">
            <div>
              <span>{{ table.name }}</span>
              <span
                v-if="table.type === TableType.SQL"
                class="remark-info"
              >
                ({{ $t('remoteDbConnection.sqlCreateTable') }})
              </span>
            </div>
            <div class="actions">
              <a
                href="javascript:void(0)"
                class="action-link"
                @click.stop="$emit('start-edit', table.name)"
              >
                <svg-icon icon-class="edit" />
              </a>
            </div>
          </template>
          <template v-else>
            <div>
              <span>{{ table.name }}</span>
            </div>
          </template>
        </step-card-block>
      </label>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import { TableType } from '../../../constants'

export default defineComponent({
  name: 'TableList',
  components: {
    StepCardBlock
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    tableList: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const selectedTableList = computed({
      get () {
        return props.value
      },
      set (value) {
        emit('input', value)
      }
    })

    return {
      TableType,
      selectedTableList
    }
  }
})
</script>

<style lang="scss" scoped>
.table-list {
  max-height: 250px;
  overflow: auto;

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  &__item-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0.75rem 1.5rem;

    .checkbox-label {
      margin-right: 1rem;
    }

    .actions {
      margin-left: auto;
    }
  }

  &--disabled &__item-container {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.action-link {
  color: $theme-color-primary;
  text-align: center;
  width: 24px;
}

.remark-info {
  color: #ffdf6f;
  font-size: 14px;
}
</style>
