<template>
  <div class="select-connection">
    <spinner
      v-if="isLoadingConnectionList"
      :title="$t('editing.loading')"
      class="spinner-container"
      size="50"
    />
    <history-connection-info-list
      v-else
      :connection-info-list="connectionInfoList"
      :connecting-connection-index="connectingConnectionIndex"
      @click-connection="handleClickConnection"
      @edit-connection="handleEditConnection"
      @delete-connection="handleDeleteConnection"
      @cancel-connect="handleCancelConnect"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { useStepSetupConnectionContext } from '../composable'
import { delay } from '@/utils/general'
import HistoryConnectionInfoList from './HistoryConnectionInfoList.vue'

export default defineComponent({
  name: 'SelectConnection',
  components: {
    HistoryConnectionInfoList
  },
  setup () {
    const {
      groupId,
      isLoading,
      handleConnect,
      handleCancelConnect,
      handleStartEditConnection,
      getConnections,
      deleteConnection,
      formatInfo
    } = useStepSetupConnectionContext()
    const connectionList = ref([])
    const currentSelectedIndex = ref(-1)
    const isLoadingConnectionList = ref(false)
    const connectionInfoList = computed(() => {
      return connectionList.value.map(formatInfo)
    })
    const connectingConnectionIndex = computed(() => {
      return isLoading.value && currentSelectedIndex.value !== -1
        ? currentSelectedIndex.value
        : -1
    })

    async function getConnectionList () {
      isLoadingConnectionList.value = true
      await Promise.all([
        delay(500),
        (async () => {
          connectionList.value = await getConnections(groupId.value)
        })()
      ])
      isLoadingConnectionList.value = false
    }
    function handleClickConnection (index) {
      if (isLoading.value) return
      currentSelectedIndex.value = index
      handleConnect(connectionList.value[index].id)
    }
    function handleEditConnection (index) {
      const { id, groupId, ...connectionData } = connectionList.value[index]
      connectionData.password = ''
      handleStartEditConnection({
        connectionId: id,
        connectionData
      })
    }
    async function handleDeleteConnection (index) {
      const connectionId = connectionList.value[index].id
      connectionList.value.splice(index, 1)
      await deleteConnection(connectionId)
    }

    onMounted(() => {
      getConnectionList()
    })

    return {
      isLoadingConnectionList,
      connectionInfoList,
      connectingConnectionIndex,
      handleCancelConnect,
      handleDeleteConnection,
      handleEditConnection,
      handleClickConnection
    }
  }
})
</script>

<style lang="scss" scoped>
.select-connection {
  min-height: 300px;
}
</style>
