<template>
  <div>
    <input
      style="display: none;"
      ref="fileUploadInput"
      :accept="acceptFileTypes"
      type="file"
      name="fileUploadInput"
      multiple
      @change="handleSelectFile($event.target.files)"
    >
    <template v-if="selectedFiles.length === 0">
      <step-card-block class="file-select">
        <upload-block
          :bottom-message="$t('editing.clickToSelectFiles')"
          :drag-enter="isDragEnter"
          class="empty-upload-block"
          @create="startSelectFile"
          @drop.native.prevent="dropFiles($event)"
          @dragover.native.prevent
          @dragenter.native="isDragEnter = true"
          @dragleave.native="isDragEnter = false"
        >
          <div
            slot="uploadLimit"
            class="empty-upload-block__remark"
          >
            <div class="remark__title">
              【{{ $t('editing.uploadLimitTitle') }}】
            </div>
            <div class="remark__body">
              <div class="remark__content">
                1. {{ $t('editing.uploadLimitFileType') }}
              </div>
              <div class="remark__content">
                2. {{ $t('editing.uploadLimitCount', { countLimit: fileCountLimit }) }}
              </div>
              <div class="remark__content">
                3. {{
                  $t('editing.uploadLimitSize', {
                    limitSize: license.maxUploadSize === -1
                      ? '&#8734;'
                      : shortenDataCapacityNumber(license.maxUploadSize)
                  })
                }}
              </div>
            </div>
            <div class="remark__content">
              4. {{ $t('editing.uploadLimitContent') }}
            </div>
          </div>
        </upload-block>
      </step-card-block>
    </template>
    <template v-else>
      <div
        :class="{ droppable: isDragEnter }"
        @dragover.prevent
        @dragenter="isDragEnter = true"
        @dragleave="isDragEnter = false"
        @drop.prevent="dropFiles($event)"
      >
        <div
          v-if="enableToUploadFiles.length > 0"
          class="result-block"
        >
          <div class="block-title">
            {{ $t('editing.canUpload') }}
          </div>
          <step-card-block class="list-container">
            <file-status-list
              :file-status-list="enableToUploadFiles"
              @delete="handleDeleteSelectedFile(enableToUploadFiles[$event].index)"
            />
          </step-card-block>
        </div>
        <div
          v-if="unableToUploadFiles.length > 0"
          class="result-block"
        >
          <div class="block-title">
            {{ $t('editing.unableUpload') }}
          </div>
          <step-card-block class="list-container">
            <file-status-list
              :file-status-list="unableToUploadFiles"
              @delete="handleDeleteSelectedFile(unableToUploadFiles[$event].index)"
            />
          </step-card-block>
        </div>
      </div>
      <div class="file-chosen-info">
        <span
          :class="{ 'is-warning': !hasEnoughRemainingDataStorageSpace }"
          class="file-chosen-remark"
        >
          {{
            hasEnoughRemainingDataStorageSpace
              ? $t('editing.selectedTablesWaitingToUpload', {
                num: enableToUploadFiles.length,
                size: byteToMB(totalTransmitDataBytes)
              })
              : $t('editing.uploadFilesExceedDataStorageMessage', {
                count: enableToUploadFiles.length,
                remaining: byteToMB(remainingDataStorageSpaceBytes),
                totalFileSize: byteToMB(totalTransmitDataBytes)
              })
          }}
        </span>
        <button
          class="btn-m btn-secondary btn-has-icon"
          @click="startSelectFile"
        >
          <svg-icon
            icon-class="file-plus"
            class="icon"
          />
          {{ fileCountLimit > 1 ? $t('editing.addFile') : $t('fileDataUpdate.reChoose') }}
        </button>
      </div>
    </template>
    <step-footer-button-list-block>
      <button
        class="btn btn-outline"
        @click="handleExit"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        v-if="enableToUploadFiles.length > 0"
        class="btn btn-default"
        @click="handleClickNextStep"
      >
        {{ $t('button.confirmUpload') }}
      </button>
    </step-footer-button-list-block>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { setupStepLocalFileSelectFiles } from './composable'

import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import StepFooterButtonListBlock from '@/modules/shared/flow/components/StepFooterButtonListBlock.vue'
import UploadBlock from '@/components/UploadBlock.vue'
import FileStatusList from './components/FileStatusList.vue'

export default defineComponent({
  name: 'Step',
  components: {
    StepCardBlock,
    StepFooterButtonListBlock,
    UploadBlock,
    FileStatusList
  },
  setup (props, ctx) {
    return setupStepLocalFileSelectFiles(ctx)
  }
})
</script>
<style lang="scss" scoped>
.file-select {
  overflow: hidden;
  padding: 0;
}

.empty-upload-block {
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;

  &__remark {
    color: $theme-color-warning;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;

    .remark {
      &__title {
        margin-bottom: 4px;
      }

      &__body {
        display: flex;
      }

      &__content:not(:last-child) {
        &::after {
          color: #6c8281;
          content: '/';
          padding: 0 8px;
        }
      }
    }
  }
}

.result-block {
  margin-bottom: 1rem;

  .block-title {
    margin-bottom: 1rem;
  }
}

.file-chosen-info {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin-bottom: 1rem;

  .file-chosen-remark {
    margin-right: 16px;

    &.is-warning {
      color: $theme-color-danger;
    }
  }
}

.droppable .list-container {
  background-color: #485454;
}

</style>
