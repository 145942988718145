<template>
  <step-card-block
    type="dark"
    class="file-selected-result"
  >
    <div class="result-title">
      {{ $t('editing.alreadySelect') }}
    </div>
    <div
      v-if="selectedFiles.length === 0"
      class="result-empty"
    >
      {{ $t('editing.selectYet') }}
    </div>
    <selected-file-list
      :selected-files="selectedFiles"
      @remove-selected-file="$emit('remove-selected-file', $event)"
    />
  </step-card-block>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import SelectedFileList from './SelectedFileList.vue'

export default defineComponent({
  name: 'SelectedResultBlock',
  components: {
    StepCardBlock,
    SelectedFileList
  },
  props: {
    selectedFiles: {
      type: Array,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.file-selected-result {
  flex-basis: 40%;
  height: 100%;
  overflow: auto;

  .result-title {
    margin-bottom: 1rem;
  }

  .result-empty {
    color: #a7a7a7;
    font-size: 14px;
  }
}
</style>
