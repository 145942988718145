import { defineFlowContext } from '@/modules/shared/flow/composable/flow'
import { createModelUploadFilesContext, FLOW_ID as MODEL_FLOW_ID } from './modelFlow'
// import { createFlowUploadFilesFlowContext, FLOW_ID as PROCESS_FLOW_ID } from './processFlow'
import { ImportType } from '../constants'

/**
 * @typedef {object} ImportFlowContextOptions
 * @property {keyof ImportType} importType
 * @property {import('@vue/composition-api').SetupContext['emit']} emit
 */

export const {
  registerFlowContext: registerImportFlowContext,
  useFlowContext: useImportFlowContext
} = defineFlowContext({
  /** @param {ImportFlowContextOptions} */
  createFlowContext ({ importType, emit }) {
    const createContext = {
      [ImportType.MODEL]: createModelUploadFilesContext
      // [ImportType.PROCESS]: createFlowUploadFilesFlowContext
    }[importType]

    return createContext({ emit })
  }
})

/** @type {(ctx: any) => ctx is ReturnType<typeof createModelUploadFilesContext>} */
export const isModelUploadFilesContext = (ctx) => ctx?.flowId === MODEL_FLOW_ID
export function useModelUploadFilesContext () {
  const flowContext = useImportFlowContext()
  if (!isModelUploadFilesContext(flowContext)) {
    throw new Error('Current flow is not a model flow')
  }
  return flowContext
}

/** @type {(ctx: any) => ctx is ReturnType<typeof createFlowUploadFilesFlowContext>} */
// export const isFlowUploadFilesContext = (ctx) => ctx?.flowId === PROCESS_FLOW_ID
// export function useFlowUploadFilesContext () {
//   const flowContext = useImportFlowContext()
//   if (!isFlowUploadFilesContext(flowContext)) {
//     throw new Error('Current flow is not a process flow')
//   }
//   return flowContext
// }
