import { analysisFile as analyzeFile } from '@/API/File'
import { createBasicConfirmSheetsContext } from './basic'

/**
 * @returns {import('./basic').UseAnalyzeFileOperations}
 */
function useAnalyzeFileOperations () {
  return {
    analyzeFile
  }
}

export function createLocalFileConfirmSheetsContext () {
  return createBasicConfirmSheetsContext({
    useAnalyzeFileOperations
  })
}
